import { RouteRecordRaw } from "vue-router";

export const workspaceRoutes = (workspaceChildrens: RouteRecordRaw[]): RouteRecordRaw[] => {
    const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

    return [
        {
            path: "workspaces",
            component: EmptyRouterView,
            meta: {
                title: "base.breadcrumb.text_workspaces",
                hideBreadcrumb: true,
            },
            children: [
                {
                    path: "",
                    name: "MainWorkspace",
                    redirect: { name: "Dashboard" },
                },
                ...workspaceChildrens,
            ],
        },
        {
            path: "workspaces/:workspaceId/workspace-settings",
            name: "WorkspaceSettings",
            component: () => import("@/modules/base/user/views/workspace-settings.vue"),
            meta: {
                title: "base.breadcrumb.text_workspace_settings",
                hideBreadcrumb: true,
            },
        },
    ];
};
