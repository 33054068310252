import { AxiosInstance } from "axios";

import type {
    TProject,
    TCreateProjectParams,
    TGetProjectsParams,
    TToggleProjectStatusParams,
    TUpdateProjectParams,
    TCheckProjectDomainOutput,
    TGetProjectsOutput,
} from "./types/project.types";

export class ProjectHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async createProject({ name, url, workspaceId }: TCreateProjectParams): Promise<TProject> {
        const { data } = await this.httpClient.post(`/workspaces/${workspaceId}/projects`, {
            name: name,
            url: url,
        });

        return data as TProject;
    }

    async getProject({
        workspaceId,
        projectId,
    }: {
        workspaceId: number | string;
        projectId: number | string;
    }): Promise<TProject> {
        const { data } = await this.httpClient.get<TProject>(
            `workspaces/${workspaceId}/projects/${projectId}`,
        );

        return data as TProject;
    }

    async getProjects({
        workspaceId,
        inactives,
        page = 1,
        limit = 10,
    }: TGetProjectsParams): Promise<TGetProjectsOutput> {
        const { data } = await this.httpClient.get<TGetProjectsOutput>(
            `/workspaces/${workspaceId}/projects`,
            {
                params: {
                    inactives,
                    page,
                    limit,
                },
            },
        );

        return {
            projects: data.projects,
            count: data.count,
        };
    }

    async updateProject({ workspaceId, projectId, name }: TUpdateProjectParams): Promise<TProject> {
        const { data } = await this.httpClient.put(
            `workspaces/${workspaceId}/projects/${projectId}`,
            {
                name,
            },
        );

        return data as TProject;
    }

    async disableProjectStatus({
        workspaceId,
        projectId,
    }: TToggleProjectStatusParams): Promise<{ updated: boolean }> {
        const { data } = await this.httpClient.patch(
            `workspaces/${workspaceId}/projects/${projectId}/disable`,
        );

        return data as { updated: boolean };
    }

    async enableProjectStatus({
        workspaceId,
        projectId,
    }: TToggleProjectStatusParams): Promise<{ updated: boolean }> {
        const { data } = await this.httpClient.patch(
            `workspaces/${workspaceId}/projects/${projectId}/enable`,
        );

        return data as { updated: boolean };
    }

    async checkProjectDomain(url: string): Promise<TCheckProjectDomainOutput> {
        const { data } = await this.httpClient.get("/projects/verify-url", {
            params: {
                url,
            },
            headers: {
                NeedAuth: false,
            },
        });

        return data as TCheckProjectDomainOutput;
    }
}
