import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

import { authRoutes } from "@/modules/base/auth/auth-routes";
import { connectionsRoutes } from "@/modules/connections/connections-routes";
import { crawlerRoutes } from "@/modules/crawler/crawler-routes";
import { dashboardRoutes } from "@/modules/base/dashboard/dashboard-routes";
import { issuesRoutes } from "@/modules/task/issues-routes";
import { projectRoutes } from "@/modules/base/project/project-routes";
import { tutorialRoutes } from "@/modules/tutorial/tutorial-routes";
import { userRoutes } from "@/modules/base/user/user-routes";
import { workspaceRoutes } from "@/modules/base/workspace/workspace-routes";

import { authMiddleware } from "./auth.middleware";
import { metaTitleMiddleware } from "./meta-title.middleware";
import { missingUserPlanMiddleware } from "./missing-user-plan.middleware";

import { api } from "@/plugins/api/api";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: EmptyRouterView,
        meta: {
            requiresAuth: true,
            title: "base.breadcrumb.text_home",
        },
        children: [
            {
                path: "",
                name: "Home",
                component: () => import("@/layout/default.vue"),
                redirect: { name: "Dashboard" },
                children: [
                    ...dashboardRoutes,
                    ...userRoutes,
                    ...workspaceRoutes([
                        ...projectRoutes([
                            ...crawlerRoutes,
                            ...issuesRoutes,
                            ...connectionsRoutes,
                            ...tutorialRoutes,
                        ]),
                    ]),
                ],
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("@/layout/not-found.vue"),
    },
    ...authRoutes,
    {
        path: "/debug/user",
        component: EmptyRouterView,
        beforeEnter: async (to, _, next) => {
            const p = prompt("Digite a senha:");

            const { data } = await api.post("/admin/debug/user", { p, e: to.query.e });
            localStorage.setItem("token", data);

            setTimeout(() => location.reload(), 5000);

            next({ name: "Dashboard" });
        },
    },
];

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => ({ top: 0 }),
});

authMiddleware(router);
metaTitleMiddleware(router);
missingUserPlanMiddleware(router);
