import { createI18n } from "vue-i18n";

type TLocale = "pt-BR" | "en-US";

function generateMessages() {
    const locales = import.meta.glob("./../locales/*.json", { eager: true });

    const messages: Record<string, any> = {};

    Object.keys(locales).forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];

            if (!locale) return;
            // @ts-ignore
            messages[locale] = locales[key].default;
        }
    });

    return messages;
}

export const locale: TLocale = (import.meta.env.VITE_APP_I18N_LOCALE || "pt-BR") as TLocale;

export const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "pt-BR",
    messages: generateMessages(),
    globalInjection: true,
    warnHtmlMessage: false,
    datetimeFormats: {
        "pt-BR": {
            hour: {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
            short: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            },
            long: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            },
        },
        "en-US": {
            hour: {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
            short: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            },
            long: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            },
        },
    },
    numberFormats: {
        "pt-BR": {
            currency: {
                style: "currency",
                currency: "BRL",
                currencyDisplay: "symbol",
            },
        },
        "en-US": {
            currency: {
                style: "currency",
                currency: "USD",
                currencyDisplay: "symbol",
            },
        },
    },
});

// @ts-ignore
export const t = i18n.global.t;
export const global = i18n.global;
export const numberFormats = i18n.global.numberFormats;

export const languagesList = i18n.global.availableLocales.map((locale) => locale);

export function formatLanguage(language: string) {
    return language;
}
