import axios from "axios";

import { useAuthStore } from "@/modules/base/auth/auth.store";
import { AxiosRequestHeaders } from "axios";

export const api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 1000 * 60 * 5, // 5 minutes
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
});

export function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");

    api.defaults.headers.common["Authorization"] = null;
    window.location.href = window.location.origin + "/login";
}

const missingTokenMessage = "Missing user token.";

api.interceptors.request.use(
    (config) => {
        if (!config.headers) config.headers = {} as AxiosRequestHeaders;

        const isPublicRoute = config.headers.NeedAuth === false;
        if (isPublicRoute) {
            return config;
        }

        const token = localStorage.getItem("token");

        if (!token) {
            throw new Error(missingTokenMessage);
        }

        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

let isRefreshing = false;

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (isRefreshing) return;

        const authStore = useAuthStore();

        if (!error.config) {
            error.config = {};
        }

        if (!error.config.headers) {
            error.config.headers = {} as AxiosRequestHeaders;
        }

        const isPublicRoute = error.config.headers.NeedAuth === false;

        if (
            !isPublicRoute &&
            (error.response?.status === 401 || error.message === missingTokenMessage)
        ) {
            isRefreshing = true;

            let user = authStore.user;
            if (!user) {
                user = await authStore.refetchCurrentUser();
            }

            const refreshToken = localStorage.getItem("refresh_token");

            if (!user || !refreshToken) {
                authStore.logout();
                return;
            }

            await authStore.refreshTokens({
                userId: user.id,
                currentRefreshToken: refreshToken,
            });

            isRefreshing = false;

            return api(error.config);
        } else {
            return Promise.reject(error);
        }
    },
);
