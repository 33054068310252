//https://paul-thebaud.github.io/v-phone-input/

import { t } from "./i18n";
import "flag-icons/css/flag-icons.min.css";
import "v-phone-input/dist/v-phone-input.css";
import { createVPhoneInput } from "v-phone-input";

export const vPhoneInput = createVPhoneInput({
    label: t("user.settings.phone_validation_phone_number"),
    countryLabel: t("user.settings.phone_validation_country"),
    countryIconMode: "svg",

    invalidMessage: ({ label, example }) =>
        `${t("user.settings.phone_validation_text_field")} "${label}" ${t("user.settings.phone_validation_text_field_2")} ${example} ).`,
});
