import { AxiosInstance } from "axios";

import type {
    TPlansAndExtras,
    TStripeProducts,
    TCurrency,
    TStripePaymentIntent,
    TUserPlan,
} from "./types/payment.types";

export class PaymentHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async getPlansAndExtras(currency: TCurrency): Promise<TPlansAndExtras> {
        const { data } = await this.httpClient.get("/payment/products", {
            params: {
                currency,
            },
        });

        return data as TPlansAndExtras;
    }

    async getClientSecretKey(
        products: TStripeProducts,
        isTrial: boolean,
    ): Promise<TStripePaymentIntent> {
        const { data } = await this.httpClient.post("/payment/create", {
            products,
            isTrial,
        });

        return data;
    }

    async getUserPlan(): Promise<TUserPlan> {
        const { data } = await this.httpClient.get("/users/user-plan");

        return data;
    }

    async updateCustomerPaymentMethod(paymentMethodId: string) {
        await this.httpClient.put("/payment/subscription/default-payment-method", {
            paymentMethodId,
        });
    }

    async getSubscriptionPreview(products: TStripeProducts) {
        const data = await this.httpClient.post("/payment/subscription-proration-preview", {
            products,
        });
        return data;
    }

    async submitProrationPayment(products: TStripeProducts) {
        const data = await this.httpClient.put("/payment/subscription", {
            products,
        });
        return data;
    }
}
