<template>
    <svg
        id="logosandtypes_com"
        data-name="logosandtypes com"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150 150"
        fill="#f71963"
    >
        <path
            class="cls-1"
            d="M135.79,18.35H29.11c-8.27,0-13.57,8.75-9.7,16.02l10.67,20.1H10.73c-3.73-.07-7.07,3.14-7.11,6.87-.04,1.22,.25,2.43,.82,3.5l34.32,64.63c2.42,4.94,10.15,4.95,12.57,0,0,0,9.32-17.46,9.32-17.46l11.7,22.03c4.12,7.75,15.27,7.76,19.4,.02l53.47-100.11c3.78-7.08-1.38-15.62-9.44-15.62Zm-47.92,42.77l-23.06,43.18c-1.62,3.28-6.75,3.29-8.37,0,0,0-22.83-42.99-22.83-42.99-1.21-2.17-.34-5.13,1.84-6.3,.71-.4,1.51-.61,2.33-.62h46.02c3.34-.11,5.75,3.82,4.07,6.73Z"
        />
    </svg>
</template>
