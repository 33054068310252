import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "./vuetify.scss";
import { createVuetify } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";

import { customSVGs } from "@/components/icons";

export const primaryColor = "#FF5a00";
export const secondaryColor = "#0049d5";
export const successColor = "#22C55E";
export const mutedForegroundColor = "#A8A28E";

const light = {
    dark: false,
    colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        success: successColor,
        warning: "#EA580C",
        error: "#C40101",
        info: "#117A85",
        grey: "#F8F8F8",
        surface: "#FFFFFF",
        muted: "#F4F4F5",
        "muted-foreground": mutedForegroundColor,
    },
};

const dark = {
    dark: true,
    colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        success: successColor,
        warning: "#EA580C",
        error: "#C40101",
        info: "#117A85",
        grey: "#121214",
        surface: "#23201F",
        muted: "#262626",
        "muted-foreground": mutedForegroundColor,
    },
};

export const vuetify = createVuetify({
    display: {
        mobileBreakpoint: "sm",
    },
    defaults: {
        global: {
            elevation: 0,
        },
        VBtn: {
            class: "font-weight-regular text-body-2 px-3",
        },
        VCheckbox: {
            color: "success",
        },
        VFileInput: {
            density: "comfortable",
        },
        VLabel: {
            style: "opacity: 1; font-size: 14px;",
        },
        VList: {
            class: "rounded-lg",
        },
        VListItem: {
            class: "rounded-lg",
        },
        VListItemTitle: {
            class: "text-small",
        },
        VRadioGroup: {
            color: "primary",
        },
        VSelect: {
            color: "primary",
            variant: "outlined",
            density: "comfortable",
        },
        VSlider: {
            thumbColor: "primary",
        },
        VSwitch: {
            color: "success",
        },
        VTextField: {
            variant: "outlined",
            class: "rounded-lg",
            density: "comfortable",
        },
        VTooltip: {
            maxWidth: "340px",
        },
    },
    theme: {
        defaultTheme: "light",
        themes: {
            light,
            dark,
        },
    },
    icons: {
        defaultSet: "mdi",
        sets: {
            mdi,
            custom: customSVGs,
        },
    },
});
