import { workspaceHttp } from "@/plugins/api/http-instances";
import { t } from "@/plugins/i18n";
import { sendFeedback } from "@/utils/send-feedback-helper";
import type {
    TCheckDomainParams,
    TCreateWorkspaceParams,
    TUpdateWorkspaceParams,
    TInvitesSend,
    TDeleteInvite,
    TConfirmUserInvite,
    TValidateInviteToken,
    TAllWorkspacesInvites,
} from "./types/workspace.types";
import { formatS3Path } from "@/utils/format-s3-path-helper";

export const workspaceService = {
    getWorkspaces,
    createWorkspace,
    getWorkspaceUsers,
    updateWorkspace,
    checkDomain,
    workspaceInviteCallback,
    validateInviteToken,
    addWorkspaceInvitedUser,
    sendInvites,
    getAllWorkspaceInvites,
    deleteWorkspaceInvite,
    resendInvite,
    removeWorkspaceUser,
};

async function getWorkspaces() {
    try {
        const workspaces = await workspaceHttp.getWorkspaces();

        workspaces.forEach((workspace) => {
            if (!workspace.attachments.length) return;

            const mainAttachment = workspace.attachments.find(
                (attachment) => attachment.type === "PROFILE_IMAGE",
            );
            if (!mainAttachment) return;

            workspace.image_url = formatS3Path(mainAttachment.key);
        });

        return workspaces || [];
    } catch (err) {
        sendFeedback({ text: t("dashboard.projects.err_get_workspaces") }).error();

        console.error(err);
        throw err;
    }
}

async function createWorkspace({ name, usage }: TCreateWorkspaceParams) {
    try {
        const createdWorkspace = await workspaceHttp.createWorkspace({
            name,
            usage,
        });

        return createdWorkspace;
    } catch (err) {
        sendFeedback({ text: t("workspace.create.err_create_workspace") }).error();

        console.error(err);
    }
}

async function getWorkspaceUsers(workspaceId: number) {
    try {
        const users = await workspaceHttp.getWorkspaceUsers(workspaceId);

        return users;
    } catch (err) {
        sendFeedback({ text: t("dashboard.projects.err_get_workspace_users") }).error();

        console.error(err);
        return [];
    }
}

async function updateWorkspace({ workspaceId, name, usage }: TUpdateWorkspaceParams) {
    try {
        const updatedWorkspace = await workspaceHttp.updateWorkspace({ workspaceId, name, usage });

        sendFeedback({ text: t("user.settings.text_success_update_workspace") }).success();

        return updatedWorkspace;
    } catch (err) {
        sendFeedback({ text: t("user.settings.text_err_update_workspace") }).error();

        console.error(err);
    }
}

async function checkDomain({ workspaceId, url }: TCheckDomainParams) {
    try {
        const checkDomainData = await workspaceHttp.checkDomain({ workspaceId, url });

        return checkDomainData;
    } catch (err) {
        sendFeedback({ text: t("project.create.err_check_project_domain") }).error();

        console.error(err);
        throw err;
    }
}

async function workspaceInviteCallback(state: string) {
    try {
        await workspaceHttp.workspaceInviteCallback(state);
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_invite_workspace") }).error();
        console.error(err);
        throw err;
    }
}

async function sendInvites({ workspaceId, emails }: TInvitesSend) {
    try {
        const invitesSend = await workspaceHttp.sendWorkspaceInvites(workspaceId, emails);
        sendFeedback({ text: t("workspace.invite.success_send_invites") }).success();
        return invitesSend;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_send_invites") }).error();

        console.error(err);
        throw err;
    }
}

async function validateInviteToken(token: string): Promise<TValidateInviteToken> {
    try {
        const validateTokenData = await workspaceHttp.validateWorkspaceInviteToken(token);

        return validateTokenData;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_invite_workspace") }).error();
        console.error(err);
        return { isValid: false, status: "INVALID", workspaceId: 0, email: "", reason: "" };
    }
}

async function addWorkspaceInvitedUser(
    workspaceId: number,
    params: {
        language?: string;
        jwt: string;
        name?: string;
        email: string;
        password?: string;
        lastName?: string;
        phoneNumber?: string;
    },
): Promise<TConfirmUserInvite> {
    try {
        const { language, jwt, name, lastName, password, email, phoneNumber } = params;

        const userInvite = await workspaceHttp.addWorkspaceInvitedUser(workspaceId, {
            language: language || undefined,
            jwt,
            name: name || undefined,
            lastName: lastName || undefined,
            password: password || undefined,
            email,
            phoneNumber: phoneNumber || undefined,
        });

        return userInvite;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_invite_workspace") }).error();
        console.error(err);
        throw err;
    }
}

async function getAllWorkspaceInvites(
    workspaceId: number,
    page = 1,
    limit = 10,
): Promise<TAllWorkspacesInvites> {
    try {
        const invites = await workspaceHttp.getAllWorkspaceInvites(workspaceId, page, limit);

        return invites;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_get_invites") }).error();
        console.error(err);
        return {} as TAllWorkspacesInvites;
    }
}

async function deleteWorkspaceInvite(
    workspaceId: number,
    inviteId: number,
): Promise<TDeleteInvite> {
    try {
        const data = await workspaceHttp.deleteWorkspaceInvite(workspaceId, inviteId);
        sendFeedback({ text: t("workspace.invite.success_delete_invite") }).success();
        return data;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_delete_invite") }).error();

        console.error(err);
        return { workspaceId: 0, inviteId: 0 };
    }
}

async function resendInvite(workspaceId: number, inviteId: string) {
    try {
        const data = await workspaceHttp.resendInvite(workspaceId, inviteId);
        sendFeedback({ text: t("workspace.invite.success_resend_invite") }).success();
        return data;
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_resend_invite") }).error();

        console.error(err);
        return { workspaceId: 0, inviteId: 0 };
    }
}

async function removeWorkspaceUser(workspaceId: number, userId: number) {
    try {
        await workspaceHttp.removeWorkspaceUser(workspaceId, userId);
    } catch (err) {
        sendFeedback({ text: t("workspace.invite.err_remove_user") }).error();
        console.error(err);
    }
}
