import { defineStore } from "pinia";

export type TAppThemes = "light" | "dark";

type TAppStoreState = {
    environment: "dev" | "qa" | "production";
    theme: TAppThemes;
    isMobile: boolean;
    isLeftSideBarClosed: boolean;
    isFeedbackDrawerOpen: boolean;
    isUpgradeModalOpen: boolean;
};

export const useAppStore = defineStore("app", {
    state: (): TAppStoreState => ({
        environment: import.meta.env.VITE_ENVIRONMENT,
        theme: "light",
        isMobile: false,
        isLeftSideBarClosed: false,
        isFeedbackDrawerOpen: false,
        isUpgradeModalOpen: false,
    }),

    getters: {
        textColor: (state) => (state.theme === "dark" ? "white" : "black"),
    },

    actions: {
        toggleMobile(value: boolean) {
            this.isMobile = value;
        },

        toggleSideBar(value: boolean) {
            this.isLeftSideBarClosed = value;
        },

        toggleFeedbackDrawer(value: boolean) {
            this.isFeedbackDrawerOpen = value;
        },

        toggleUpgradeModal(value: boolean) {
            this.isUpgradeModalOpen = value;
        },
    },
});
