import { useNotification } from "@kyvg/vue3-notification";

interface FeedbackOptions {
    title?: string;
    text?: string;
    duration?: number;
}

export function sendFeedback({ title, text, duration = 5000 }: FeedbackOptions) {
    const { notify } = useNotification();

    const commonData = { title, text, duration };

    return {
        success() {
            notify({
                type: "success",
                ...commonData,
            });
        },

        warn() {
            notify({
                type: "warn",
                ...commonData,
            });
        },

        error() {
            notify({
                type: "error",
                ...commonData,
            });
        },
    };
}
