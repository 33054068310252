import { AxiosInstance } from "axios";

import type { TSubmitUserFeedbackParams } from "./types/customer-support.types";

export class CustomerSupportHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    submitUserFeedback(formData: TSubmitUserFeedbackParams) {
        return this.httpClient.post("/customer-support/feedback", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}
