import { AxiosInstance } from "axios";

import { TFileUploadParams } from "./upload.types";

export class UploadHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async upload({
        files,
        module,
        type,
        issueId,
        workspaceId,
        projectId,
    }: TFileUploadParams): Promise<void> {
        await this.httpClient.postForm(
            "/uploads",
            { files },
            {
                params: {
                    module,
                    type,
                    taskId: issueId,
                    workspaceId,
                    projectId,
                },
            },
        );
    }
}
