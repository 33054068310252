import { RouteRecordRaw } from "vue-router";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");
export const crawlerRoutes: RouteRecordRaw[] = [
    {
        path: "crawlers",
        component: EmptyRouterView,
        meta: {
            title: "base.breadcrumb.text_crawler",
        },
        children: [
            {
                path: "",
                name: "CrawlerList",
                component: () => import("@/modules/crawler/views/crawler-list.vue"),
                meta: {
                    title: "base.breadcrumb.text_list",
                    hideBreadcrumb: true,
                },
            },
            {
                path: "last-crawler-dashboard",
                name: "LastCrawlerDashboard",
                meta: {
                    title: "base.breadcrumb.text_dashboard",
                },
                component: () => import("@/modules/crawler/views/dashboard-crawler.vue"),
            },
            {
                path: "new",
                name: "NewCrawler",
                component: () => import("@/modules/crawler/views/new-crawler.vue"),
                meta: {
                    metaTitle: "crawler.create.text_title",
                    title: "base.breadcrumb.text_create",
                },
            },
            {
                path: ":crawlerId",
                component: EmptyRouterView,
                meta: {
                    metaTitle: "crawler.dashboard.text_title",
                    title: "base.breadcrumb.text_dashboard",
                },
                children: [
                    {
                        path: "",
                        name: "DashboardCrawler",
                        component: () => import("@/modules/crawler/views/dashboard-crawler.vue"),
                    },
                    {
                        path: "urls",
                        component: EmptyRouterView,
                        meta: {
                            metaTitle: "base.breadcrumb.text_crawled_urls",
                            title: "base.breadcrumb.text_crawled_urls",
                        },
                        children: [
                            {
                                path: "",
                                name: "CrawledUrls",
                                component: () => import("@/modules/crawler/views/crawled-urls.vue"),
                            },
                            {
                                path: ":crawledUrlId",
                                name: "CrawledUrlOverview",
                                component: () =>
                                    import("@/modules/crawler/views/crawler-url-overview.vue"),
                                meta: {
                                    metaTitle: "base.breadcrumb.text_url_overview",
                                    title: "base.breadcrumb.text_url_overview",
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
