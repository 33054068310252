<template>
    <svg
        version="1.1"
        id="Camada_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 579.42 190.74"
        style="enable-background: new 0 0 579.42 190.74"
        xml:space="preserve"
    >
        <g>
            <path
                fill="#444041"
                d="M61.71,0c1.82,0.57,3.68,1.02,5.44,1.73c11.58,4.64,18.2,13.11,19.31,25.58c0.93,10.43-1.33,19.99-8.51,28.19
		c0.4,0.37,0.7,0.72,1.07,0.97c8.02,5.42,12.86,12.87,13.42,22.54c0.33,5.71,0.45,11.63-0.65,17.19
		c-2.56,12.97-12.94,22.25-26.25,22.85c-14.26,0.65-28.59,0.6-42.85-0.1c-12.66-0.62-22.67-12.19-22.68-24.86
		C-0.02,62.73,0.01,31.36,0.01,0C20.58,0,41.15,0,61.71,0z M15.65,67.43c0,9.01-0.11,17.6,0.04,26.19
		c0.1,5.8,4.25,10.02,10.04,10.09c12.63,0.14,25.26,0.21,37.88-0.03c7.66-0.15,13.29-6.31,13.38-13.92c0.03-2.44,0.01-4.88,0-7.33
		c-0.02-9.19-5.79-14.98-15.01-15c-11.16-0.02-22.32-0.01-33.48-0.01C24.32,67.43,20.13,67.43,15.65,67.43z M15.75,51.62
		c13.05,0,25.85,0,38.65-0.01c0.57,0,1.15-0.2,1.71-0.34c10.68-2.69,16.76-12.77,14.6-24.2c-1.27-6.73-6.41-11.31-13.27-11.37
		c-13.6-0.11-27.2-0.03-40.8-0.01c-0.26,0-0.53,0.16-0.89,0.28C15.75,27.78,15.75,39.56,15.75,51.62z"
            />
            <path
                fill="#444040"
                d="M419.59,0c0.7,0.22,1.38,0.5,2.09,0.66c12.61,2.85,22.03,14.34,22.69,27.28c0.52,10.25-1.62,19.57-8.63,27.58
		c0.45,0.39,0.76,0.73,1.14,0.98c8.3,5.63,13.04,13.39,13.44,23.44c0.21,5.25,0.39,10.64-0.58,15.75
		c-2.49,13.2-12.94,22.76-26.44,23.35c-14.45,0.64-28.97,0.53-43.42-0.16c-11.46-0.55-21.83-11.66-21.92-23.08
		c-0.25-31.94-0.08-63.88-0.08-95.82C378.45,0,399.02,0,419.59,0z M373.54,67.43c0,9.32-0.27,18.39,0.11,27.43
		c0.22,5.2,4.59,8.82,9.96,8.87c12.53,0.11,25.07,0.15,37.6-0.02c6.81-0.09,12.13-4.71,13.26-11.43c0.53-3.14,0.35-6.42,0.35-9.63
		c0.02-9.41-5.75-15.19-15.13-15.2c-13.61-0.02-27.22,0-40.83-0.01C377.12,67.43,375.38,67.43,373.54,67.43z M373.49,51.75
		c11.79,0,23.25-0.32,34.68,0.09c10.62,0.38,21.04-7.44,20.71-21.08c-0.22-9.49-5.7-15.1-15.19-15.11c-12.43-0.02-24.87-0.01-37.3,0
		c-0.94,0-1.88,0.09-2.9,0.14C373.49,27.84,373.49,39.62,373.49,51.75z"
            />
            <path
                fill="#e8424f"
                d="M303.82,0c2.47,0.75,4.98,1.39,7.4,2.28c12.65,4.67,20.95,13.36,22.94,26.84c1.93,13.03-3.19,23.5-13.71,31.31
		c-25.83,19.17-51.68,38.31-77.52,57.46c-0.47,0.35-0.97,0.65-1.69,1.13c-0.11-0.56-0.26-0.98-0.26-1.39
		c-0.01-6.64-0.09-13.29,0.01-19.93c0.12-7.88,3.7-13.98,9.98-18.63c17.52-12.97,35.04-25.95,52.54-38.96
		c4.89-3.64,5.17-9.64,0.42-12.69c-1.61-1.03-3.85-1.5-5.8-1.52c-15.07-0.12-30.15-0.07-45.22-0.07c-1.07,0-2.14,0-3.73,0
		c0-8.73,0-17.29,0-25.84C267.39,0,285.6,0,303.82,0z"
            />
            <path
                fill="#444041"
                d="M579.42,98.52c-0.23,0.49-0.53,0.96-0.68,1.48c-3.29,11.17-11.62,18.46-23.24,19.15
		c-9.07,0.54-18.24,0.61-27.29-0.09c-14.97-1.15-25.04-14.1-23.39-29.28c5.01,0,10.04,0,15.04,0c0.11,0.28,0.22,0.45,0.23,0.63
		c0.54,10.41,3.66,13.36,14.15,13.36c6.17,0,12.34,0.02,18.51-0.01c6.02-0.02,10.34-3.54,10.93-8.84
		c0.65-5.84-2.61-10.26-8.66-11.68c-10.07-2.36-20.16-4.64-30.22-7.07c-12.57-3.04-20.52-13.59-20.21-26.63
		c0.3-12.56,8.78-23.02,21.26-25.12c5.63-0.95,11.52-0.52,17.28-0.48c4.29,0.03,8.69-0.09,12.84,0.8
		c12.13,2.61,20.88,14.71,19.65,26.69c-5.03,0-10.07,0-15.31,0c-0.14-1.35-0.14-2.71-0.45-4c-1.1-4.66-5.13-7.84-10.35-7.96
		c-6.17-0.13-12.34-0.12-18.51-0.01c-5.99,0.11-10.28,4.09-10.85,9.82c-0.58,5.71,2.83,10.36,8.71,11.76
		c9.68,2.31,19.37,4.61,29.07,6.84c11.4,2.62,18.58,9.4,21.04,20.94c0.03,0.13,0.29,0.21,0.44,0.32
		C579.42,92.27,579.42,95.39,579.42,98.52z"
            />
            <path
                fill="#4a4647"
                d="M579.42,181.79c-2.45,6.75-7.47,9.28-14.38,8.82c-1.75-0.12-3.53,0.04-5.28-0.05
		c-5.61-0.31-10.32-4.11-10.87-9.64c-0.52-5.2-0.5-10.53-0.01-15.74c0.54-5.62,4.53-9.41,10.16-10.12
		c3.37-0.42,6.86-0.39,10.24-0.03c5.45,0.59,8.35,4.35,10.14,9.16c0,3.32,0,6.65,0,9.97c-8.55,0-17.11,0-25.81,0
		c0,1.74-0.12,3.29,0.02,4.82c0.4,4.24,3.3,6.88,7.55,7c1.07,0.03,2.15,0.01,3.22,0.01c7.02,0,7.9-0.58,10.42-6.84
		c1.52-0.1,3.06-0.2,4.6-0.29C579.42,179.84,579.42,180.82,579.42,181.79z M574.05,169.39c0.76-6.79-1.94-9.9-8.46-9.91
		c-1.27,0-2.54-0.01-3.81,0c-5.62,0.07-8.94,4.27-7.84,9.9C560.65,169.39,567.36,169.39,574.05,169.39z"
            />
            <path
                fill="#444041"
                d="M217.92,51.47c-5.18,0-10.15,0-15.12,0c-0.12-0.15-0.33-0.29-0.35-0.45c-1.02-8.62-4.34-11.59-12.97-11.59
		c-5.29,0-10.58-0.05-15.87,0.01c-6.28,0.08-10.52,3.82-11.21,9.74c-0.68,5.79,2.75,10.43,8.88,11.88
		c9.97,2.36,19.97,4.63,29.94,7.02c12.35,2.95,20.36,13.06,20.39,25.61c0.03,11.97-8.06,22.16-19.95,24.98
		c-1.79,0.43-3.68,0.67-5.52,0.68c-7.83,0.07-15.67,0.2-23.5-0.01c-14.95-0.4-25.76-11.67-25.82-26.64c0-0.88,0-1.76,0-2.86
		c5.09,0,10.12,0,15.53,0c0,1.06-0.05,2.19,0.01,3.32c0.33,6.3,4.68,10.54,10.99,10.59c7.34,0.06,14.69,0.05,22.04,0
		c5.82-0.04,9.99-3.73,10.62-9.25c0.58-5.11-2.9-9.9-8.3-11.18c-10.16-2.42-20.36-4.69-30.52-7.12
		c-12.66-3.02-20.54-13.33-20.36-26.5c0.17-12.54,8.56-23,21.09-25.24c5.42-0.97,11.12-0.54,16.69-0.53
		c4.29,0.01,8.7-0.2,12.85,0.66C210.18,27.23,218.44,38.24,217.92,51.47z"
            />
            <path
                fill="#464142"
                d="M127.37,119.28c-5.19,0-10.15,0-15.34,0c0-31.78,0-63.44,0-95.24c5.12,0,10.16,0,15.34,0
		C127.37,55.77,127.37,87.38,127.37,119.28z"
            />
            <path
                fill="#464142"
                d="M485.2,119.27c-5.24,0-10.2,0-15.37,0c0-31.71,0-63.29,0-95.13c5.02,0,10.12,0,15.37,0
		C485.2,55.78,485.2,87.4,485.2,119.27z"
            />
            <path
                fill="#e8424f"
                d="M334.37,93.36c0,8.77,0,17.13,0,25.69c-24.83,0-49.53,0-74.62,0c0.7-0.65,1.18-1.18,1.74-1.59
		c7.53-5.59,15.07-11.16,22.6-16.75c6.66-4.95,14.19-7.28,22.44-7.33C315.71,93.32,324.88,93.36,334.37,93.36z"
            />
            <path
                fill="#484344"
                d="M335.44,189.85c0-11.49,0-22.8,0-34.27c1.6,0,3.1,0,4.84,0c0,1.28,0,2.49,0,3.9
		c6.92-6.85,14.92-6.59,20.16,0.41c4.16-5.93,9.75-5.96,16.5-4.34c4.04,0.97,6,4.49,6.1,8.44c0.23,8.57,0.07,17.15,0.07,25.88
		c-1.65,0-3.17,0-5.07,0c0-1.81,0-3.63,0-5.45c-0.02-6.44,0.05-12.89-0.1-19.33c-0.1-4.03-1.89-5.59-5.86-5.62
		c-7.68-0.06-10.17,2.4-10.18,10.03c-0.01,6.54-0.01,13.08-0.03,19.63c0,0.18-0.11,0.36-0.22,0.72c-1.46,0-2.99,0-4.8,0
		c-0.04-0.7-0.12-1.54-0.13-2.37c-0.01-6.93,0.01-13.87-0.01-20.8c-0.01-2.81-0.28-5.64-3.26-6.96c-3.18-1.41-8.84-0.24-10.4,2.51
		c-1.38,2.44-2.41,5.37-2.57,8.14c-0.37,6.41-0.12,12.86-0.12,19.48C338.71,189.85,337.2,189.85,335.44,189.85z"
            />
            <path
                fill="#4a4647"
                d="M441.15,189.97c-1.85,0-3.31,0-5.13,0c0-2.62,0-5.22,0-7.82c-0.01-5.47,0.09-10.94-0.06-16.41
		c-0.12-4.58-2-6.24-6.56-6.25c-6.97-0.01-9.73,2.73-9.75,9.69c-0.01,6.83,0,13.66,0,20.68c-1.63,0-3.15,0-5.14,0
		c0-0.85,0-1.77,0-2.69c0-6.84,0.04-13.68-0.02-20.52c-0.02-2.87-0.12-5.93-3.51-7.02c-4.34-1.39-8.82-0.09-10.66,3.34
		c-1.1,2.06-1.8,4.56-1.89,6.9c-0.25,6.61-0.09,13.24-0.09,20.02c-1.75,0-3.28,0-5,0c0-11.33,0-22.71,0-34.28c1.49,0,3,0,4.7,0
		c0.12,1.07,0.25,2.16,0.38,3.35c7.18-6.18,15.51-5.74,19.86,1.21c3.04-5.38,8.07-5.61,13.32-5.32c4.82,0.26,8.55,3.15,9.05,7.95
		c0.52,5.03,0.39,10.13,0.47,15.2C441.2,181.89,441.15,185.78,441.15,189.97z"
            />
            <path
                fill="#494545"
                d="M227.94,174.15c-8.6,0-16.98,0-25.83,0c0.28,2.55,0.06,5.02,0.86,7.1c2.48,6.41,8.28,4.61,13.16,4.69
		c3.76,0.06,5.85-1.83,6.85-5.54c0.1-0.37,0.24-0.73,0.4-1.21c1.46,0,2.89,0,4.3,0c0.76,4.67-2.26,9.7-7.4,10.63
		c-4.53,0.82-9.37,0.88-13.93,0.24c-5.42-0.77-8.66-4.98-8.95-10.45c-0.23-4.38-0.23-8.8,0.01-13.18
		c0.34-6.04,4.1-10.46,10.04-11.22c3.91-0.5,8.04-0.36,11.94,0.26c4.85,0.77,7.66,4.34,8.45,9
		C228.36,167.47,227.94,170.64,227.94,174.15z M223.08,169.37c0.09-7.38-2.36-9.89-9.35-9.89c-0.88,0-1.76,0-2.64,0
		c-6.04,0.03-9.1,3.5-8.57,9.89C209.31,169.37,216.11,169.37,223.08,169.37z"
            />
            <path
                fill="#4a4646"
                d="M480.53,173.95c-8.52,0-16.91,0-25.88,0c0.26,2.59,0.14,5.05,0.85,7.26c0.48,1.51,1.87,3.31,3.27,3.84
		c4.38,1.63,9.11,1.8,13.38-0.15c1.8-0.82,2.8-3.37,4.16-5.13c-0.18-0.19-0.36-0.39-0.54-0.58c1.52,0,3.04,0,4.56,0
		c0.28,5.14-2.6,9.76-7.73,10.65c-4.53,0.79-9.36,0.79-13.92,0.17c-4.88-0.66-8.16-4.17-8.6-9.1c-0.47-5.31-0.51-10.74,0.07-16.02
		c0.61-5.53,4.59-9.15,10.12-9.82c3.37-0.41,6.87-0.41,10.25-0.01c5.4,0.64,9.17,4.48,9.94,10.03
		C480.86,167.93,480.53,170.87,480.53,173.95z M454.98,169.38c6.92,0,13.62,0,20.3,0c0.69-6.79-2.01-9.89-8.49-9.9
		c-0.98,0-1.95,0-2.93,0C457.5,159.49,454.54,162.72,454.98,169.38z"
            />
            <path
                fill="#484344"
                d="M326.18,172.75c0,2.15,0.11,4.3-0.02,6.44c-0.38,6.32-5.27,11.11-11.61,11.37c-2.54,0.11-5.09,0.06-7.63,0.02
		c-7.08-0.09-12.18-5-12.43-12.11c-0.13-3.8-0.13-7.62,0-11.42c0.21-6.21,3.99-10.95,10.12-11.82c3.91-0.55,8.04-0.5,11.94,0.11
		c5.79,0.91,9.34,5.43,9.63,11.25c0.1,2.05,0.02,4.1,0.02,6.15C326.19,172.75,326.18,172.75,326.18,172.75z M321.27,173
		c-0.08,0-0.17,0-0.25-0.01c0-2.15,0.18-4.31-0.04-6.44c-0.45-4.46-3.35-7-7.82-7.08c-1.86-0.03-3.72-0.03-5.58,0
		c-4.51,0.08-7.68,2.92-7.87,7.36c-0.17,4-0.18,8.02,0,12.01c0.19,4.26,3.18,7.02,7.39,7.13c2.25,0.06,4.51,0.08,6.76-0.02
		c3.68-0.15,6.5-2.6,7.02-6.25C321.2,177.51,321.15,175.24,321.27,173z"
            />
            <path
                fill="#494546"
                d="M541.42,165.73c-1.63,0-3.06,0-4.48,0c-1.65-5.16-3.16-6.25-8.67-6.25c-10.01-0.01-11.75,1.72-11.75,11.69
		c0,2.44-0.05,4.88,0.02,7.32c0.14,4.63,2.98,7.41,7.56,7.5c1.56,0.03,3.13,0.01,4.69,0c4.96-0.03,7.05-1.82,7.89-6.73
		c0.08-0.47,0.22-0.92,0.35-1.46c1.54,0,2.98,0,4.41,0c0.98,5.76-3.07,11.73-9.03,12.55c-3.74,0.51-7.67,0.52-11.39-0.05
		c-5.08-0.78-8.74-4.01-9.21-9.23c-0.5-5.49-0.48-11.11,0.12-16.58c0.56-5.12,4.07-8.55,9.22-9.24c3.91-0.52,8.03-0.48,11.92,0.13
		C538.47,156.23,541.11,159.83,541.42,165.73z"
            />
            <path
                fill="#4a4546"
                d="M282.9,177.86c1.73,0,3.26,0,4.77,0c0.17,7.6-4.63,12.72-11.9,12.71c-3.21,0-6.51,0.13-9.62-0.52
		c-5.25-1.1-8.56-5.4-8.72-10.73c-0.13-4.2-0.14-8.41,0.01-12.61c0.24-6.78,5.31-11.76,12.08-11.83c3.41-0.04,6.89-0.06,10.2,0.61
		c4.95,1,7.73,5.04,7.57,10.19c-1.42,0-2.85,0-4.31,0c-2.25-5.59-3.15-6.2-9.26-6.2c-1.18,0-2.35-0.02-3.53,0
		c-4.24,0.07-7.29,2.73-7.51,6.99c-0.22,4.29-0.2,8.6-0.02,12.89c0.16,3.88,2.99,6.45,6.85,6.61c1.47,0.06,2.94,0.02,4.41,0.02
		C280.09,185.99,282.09,184.23,282.9,177.86z"
            />
            <path
                fill="#444041"
                d="M507.15,155.5c0,1.52,0,2.85,0,4.34c-0.88,0.06-1.64,0.14-2.39,0.15c-6.68,0.12-10.25,3.58-10.37,10.35
		c-0.11,6.43-0.02,12.87-0.02,19.5c-1.62,0-3.13,0-5.04,0c0-5.66,0-11.37,0-17.08c0-5.65,0-11.31,0-17.14c1.65,0,3.16,0,4.81,0
		c0,1.39,0,2.73,0,4.74C497.8,156.22,502,154.79,507.15,155.5z"
            />
            <path
                fill="#494546"
                d="M249.55,174.61c-4.73,0-9.2,0-13.88,0c0-1.47,0-2.86,0-4.39c4.58,0,9.13,0,13.88,0
		C249.55,171.61,249.55,173,249.55,174.61z"
            />
        </g>
    </svg>
</template>
