import { defineStore } from "pinia";

import { router } from "@/plugins/router/router";
import { logout as httpClientLogout } from "@/plugins/api/api";
import { authService } from "./auth.service";

import type {
    TUser,
    TLoginParams,
    TLoginWithGoogleCallbackParams,
    TRefreshTokenParams,
    TRegisterParams,
    TUpdateUserParams,
} from "./types/auth.types";

type TAuthStoreState = {
    user: TUser | null;
    initialNameLetters: string | null;
    isLoggedIn: boolean;
    isFetchingUser: boolean;
    hasFetchedUser: boolean;
    isUserPlanExpired: boolean;
};

export const useAuthStore = defineStore("auth", {
    state: (): TAuthStoreState => ({
        user: null,
        initialNameLetters: null,
        isLoggedIn: false,
        isFetchingUser: false,
        hasFetchedUser: false,
        isUserPlanExpired: false,
    }),

    actions: {
        async registerUser({
            email,
            name,
            lastName,
            language,
            password,
            phoneNumber,
        }: TRegisterParams) {
            try {
                const createdUser = await authService.registerUser({
                    email,
                    name,
                    lastName,
                    language,
                    password,
                    phoneNumber,
                });

                if (createdUser) {
                    await this.login({ email, password, redirect: false });
                }
            } catch (err: any) {
                this.user = null;
                throw err;
            }
        },

        async registerInvitedUser({
            email,
            name,
            lastName,
            language,
            password,
            phoneNumber,
            state,
        }: TRegisterParams) {
            try {
                const registeredUser = await authService.registerInvitedUser({
                    email,
                    name,
                    lastName,
                    language,
                    password,
                    phoneNumber,
                    state,
                });
                if (registeredUser) {
                    await this.login({ email, password, redirect: true });
                }
            } catch (error) {
                this.user = null;
                throw error;
            }
        },

        async login({ email, password, redirect }: TLoginParams & { redirect?: boolean }) {
            try {
                const user = await authService.login({ email, password });
                if (!user) {
                    return router.push("/");
                }

                this.isLoggedIn = true;

                if (redirect) {
                    router.push("/dashboard");
                }
            } catch (err) {
                this.user = null;
                throw err;
            }
        },

        logout() {
            this.isLoggedIn = false;
            this.user = null;

            httpClientLogout();
        },

        async loginWithGoogle() {
            try {
                const url = await authService.loginWithGoogle();

                if (url) {
                    window.location.href = url;
                }
            } catch {
                this.user = null;
            }
        },

        async loginWithGoogleCallback(data: TLoginWithGoogleCallbackParams) {
            try {
                const user = await authService.loginWithGoogleCallback(data);

                if (user) {
                    this.isLoggedIn = true;
                }
            } catch {
                this.user = null;
            }
        },

        async refreshTokens({ userId, currentRefreshToken }: TRefreshTokenParams) {
            try {
                const data = await authService.refreshToken({
                    userId: userId,
                    currentRefreshToken: currentRefreshToken,
                });

                return data;
            } catch {
                this.logout();
            }
        },

        storeLoginIfAuthenticated() {
            const userStringified = localStorage.getItem("user");
            if (!userStringified) return;

            const localStorageUser = JSON.parse(userStringified);

            if (localStorageUser) {
                const {
                    id,
                    email,
                    email_verified,
                    name,
                    last_name,
                    language,
                    created_at,
                    image_id,
                    status,
                    updated_at,
                    attachments,
                    workspaces,
                    image_url,
                    user_plan,
                } = localStorageUser;

                this.user = {
                    ...this.user,
                    id,
                    email,
                    email_verified,
                    name,
                    last_name,
                    language,
                    created_at,
                    image_id,
                    status,
                    updated_at,
                    attachments,
                    workspaces,
                    image_url,
                    user_plan,
                } as TUser;

                this.isLoggedIn = true;
            }

            return !!localStorageUser;
        },

        async refetchCurrentUser() {
            this.isFetchingUser = true;
            try {
                const user = await authService.refetchCurrentUser();
                this.isUserPlanExpired = verifyExpiredPlan(user);
                this.user = { ...this.user, ...user };
                this.hasFetchedUser = true;

                return this.user;
            } catch (err) {
                console.error(err);
                return null;
            } finally {
                this.isFetchingUser = false;
            }
        },

        async updateUser({
            name,
            lastName,
            email,
            language,
            password,
            currentPassword,
            phoneNumber,
        }: TUpdateUserParams) {
            const user = await authService.updateUser({
                name,
                lastName,
                email: email || this.user?.email,
                language,
                password,
                currentPassword,
                phoneNumber,
            });

            const userStringified = localStorage.getItem("user");
            if (!userStringified) return;

            const localStorageUser = JSON.parse(userStringified);

            localStorage.setItem("user", JSON.stringify({ ...localStorageUser, ...user }));

            this.user = { ...localStorageUser, ...user };

            this.formatUsername();

            return user;
        },

        async deleteUser() {
            if (!this.user) return;

            await authService.deleteUser({ userId: this.user.id, removedBy: this.user.id });
            this.user = null;

            router.push("/logout");
        },

        formatUsername() {
            if (!this.user) return;

            const splitedName = this.user.name.split(" ");
            const hasLastName = !!splitedName[1];

            const firstLetterOfFirstName = splitedName[0]?.[0];

            if (hasLastName) {
                const firstLetterOfLastName = splitedName[splitedName.length - 1]?.[0];
                this.initialNameLetters = `${firstLetterOfFirstName}${firstLetterOfLastName}`;
            } else {
                const secondLetterOfFirstName = splitedName[0]?.[1];

                this.initialNameLetters = `${firstLetterOfFirstName}${
                    secondLetterOfFirstName || firstLetterOfFirstName
                }`;
            }
        },
    },
});

function verifyExpiredPlan(user: TUser) {
    const EXTRA_DAYS_UNTIL_EXPIRES = 5;
    const planExpirationDate = new Date(user?.user_plan?.expires_at);
    const planExpirationDateAfterExtraDays = new Date(
        planExpirationDate.setDate(planExpirationDate.getDate() + EXTRA_DAYS_UNTIL_EXPIRES),
    );
    return planExpirationDateAfterExtraDays < new Date();
}
