import { ComputedRef, computed } from "vue";
import { VDataTableServer } from "vuetify/components";

import { t } from "@/plugins/i18n";
import type { TIssues } from "@/modules/crawler/types/crawler.types";

export type TIssueSituation = "OPPORTUNITY" | "ALERT" | "PROBLEM";

type UnwrapReadonlyArray<A> = A extends Readonly<Array<infer I>> ? I : A;
export type TIssueHeader = UnwrapReadonlyArray<VDataTableServer["headers"]>;

export type TIssueData = {
    key: TIssues;
    priority: number;
    title: string;
    problem: string;
    solution: string;
    situation: TIssueSituation;
    headers: TIssueHeader[];
    tutorialVideo: string;
    tutorialVideoId: string;
};

export type TBaseIssues = {
    [_key in TIssues]: TIssueData;
};

export const baseIssues: ComputedRef<TBaseIssues> = computed(() => ({
    MULTIPLE_DOMAIN_WWW_TASK: {
        key: "MULTIPLE_DOMAIN_WWW_TASK",
        priority: 1,
        title: t("issues.multiple_domain_www_task.text_title"),
        problem: t("issues.multiple_domain_www_task.text_problem"),
        solution: t("issues.multiple_domain_www_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            { title: t("task.details.text_column_total"), value: "total", sortable: false },
            { title: t("task.details.text_column_domain"), value: "domain", sortable: false },
        ],
    },
    LOOP_CANONICALS_TASK: {
        key: "LOOP_CANONICALS_TASK",
        priority: 3,
        title: t("issues.loop_canonicals_task.text_title"),
        problem: t("issues.loop_canonicals_task.text_problem"),
        solution: t("issues.loop_canonicals_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_canonical_url"),
                value: "canonical_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "canonical_id", width: 300 },
                sortable: false,
            },
        ],
    },
    NOT_FOUND_IN_SITEMAP_TASK: {
        key: "NOT_FOUND_IN_SITEMAP_TASK",
        priority: 4,
        title: t("issues.not_found_in_sitemap_task.text_title"),
        problem: t("issues.not_found_in_sitemap_task.text_problem"),
        solution: t("issues.not_found_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
        ],
    },
    INTERNAL_LINKS_HTTP_HTTPS_TASK: {
        key: "INTERNAL_LINKS_HTTP_HTTPS_TASK",
        priority: 5,
        title: t("issues.internal_links_http_https_task.text_title"),
        problem: t("issues.internal_links_http_https_task.text_problem"),
        solution: t("issues.internal_links_http_https_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    MISSING_H1_TASK: {
        key: "MISSING_H1_TASK",
        priority: 6,
        title: t("issues.missing_h1_task.text_title"),
        problem: t("issues.missing_h1_task.text_problem"),
        solution: t("issues.missing_h1_task.text_solution"),
        situation: "ALERT",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
        tutorialVideo: "https://www.youtube.com/embed/9d-6HH_OK1s",
        tutorialVideoId: "9d-6HH_OK1s",
    },
    HEAVY_IMAGES_TASK: {
        key: "HEAVY_IMAGES_TASK",
        priority: 7,
        title: t("issues.heavy_images_task.text_title"),
        problem: t("issues.heavy_images_task.text_problem"),
        solution: t("issues.heavy_images_task.text_solution"),
        situation: "ALERT",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            { title: t("task.details.text_column_content_type"), value: "content_type" },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_id", width: 300 },
                sortable: false,
            },
            { title: t("task.details.text_column_size"), value: "size" },
            {
                title: t("task.details.text_column_internal"),
                value: "internal",
                key: "boolean",
                sortable: false,
            },
        ],
    },
    MISSING_IN_SITEMAP_TASK: {
        key: "MISSING_IN_SITEMAP_TASK",
        priority: 9,
        title: t("issues.missing_in_sitemap_task.text_title"),
        problem: t("issues.missing_in_sitemap_task.text_problem"),
        solution: t("issues.missing_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_url_id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_reference_type"),
                value: "found_in",
                key: "referenceType",
                sortable: false,
            },
        ],
        tutorialVideo: "https://www.youtube.com/embed/Btq1O4RTYJ8",
        tutorialVideoId: "Btq1O4RTYJ8",
    },
    DUPLICATED_H1_TASK: {
        key: "DUPLICATED_H1_TASK",
        priority: 10,
        title: t("issues.duplicated_h1_task.text_title"),
        problem: t("issues.duplicated_h1_task.text_problem"),
        solution: t("issues.duplicated_h1_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL 1",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: "URL 2",
                value: "second_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "second_id", width: 300 },
                sortable: false,
            },
            { title: t("task.details.text_column_duplicated_h1"), value: "text", sortable: false },
        ],
        tutorialVideo: "https://www.youtube.com/embed/J6PFF0IdpxA",
        tutorialVideoId: "J6PFF0IdpxA",
    },
    REDIRECT_IN_SITEMAP_TASK: {
        key: "REDIRECT_IN_SITEMAP_TASK",
        priority: 11,
        title: t("issues.redirect_in_sitemap_task.text_title"),
        problem: t("issues.redirect_in_sitemap_task.text_problem"),
        solution: t("issues.redirect_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
            {
                title: t("task.details.text_column_destination_url"),
                value: "destination_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "destination_id", width: 300 },
                sortable: false,
            },
        ],
    },
    MULTI_CANONICAL_TASK: {
        key: "MULTI_CANONICAL_TASK",
        priority: 12,
        title: t("issues.multi_canonical_task.text_title"),
        problem: t("issues.multi_canonical_task.text_problem"),
        solution: t("issues.multi_canonical_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_canonical_url"),
                value: "canonical_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "canonical_id", width: 300 },
                sortable: false,
            },
        ],
    },
    MISSING_ALT_TEXT_IMAGES_TASK: {
        key: "MISSING_ALT_TEXT_IMAGES_TASK",
        priority: 13,
        title: t("issues.missing_alt_text_images_task.text_title"),
        problem: t("issues.missing_alt_text_images_task.text_problem"),
        solution: t("issues.missing_alt_text_images_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "fromUrl",
                key: "sendToDetailed",
                headerProps: { sendTo: "fromId", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_image_url"),
                value: "image_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
        ],
    },
    MISSING_AGGREGATE_RATING_TASK: {
        key: "MISSING_AGGREGATE_RATING_TASK",
        priority: 14,
        title: t("issues.missing_aggregate_rating_task.text_title"),
        problem: t("issues.missing_aggregate_rating_task.text_problem"),
        solution: t("issues.missing_aggregate_rating_task.text_solution"),
        situation: "ALERT",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    FOUND_ONLY_IN_SITEMAP_TASK: {
        key: "FOUND_ONLY_IN_SITEMAP_TASK",
        priority: 15,
        title: t("issues.found_only_in_sitemap_task.text_title"),
        problem: t("issues.found_only_in_sitemap_task.text_problem"),
        solution: t("issues.found_only_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    MISSING_CANONICAL_TASK: {
        key: "MISSING_CANONICAL_TASK",
        priority: 18,
        title: t("issues.missing_canonical_task.text_title"),
        problem: t("issues.missing_canonical_task.text_problem"),
        solution: t("issues.missing_canonical_task.text_solution"),
        situation: "ALERT",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    CANONICAL_IN_SITEMAP_TASK: {
        key: "CANONICAL_IN_SITEMAP_TASK",
        priority: 20,
        title: t("issues.canonical_in_sitemap_task.text_title"),
        problem: t("issues.canonical_in_sitemap_task.text_problem"),
        solution: t("issues.canonical_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    JS_DEPENDENT_LINKS_TASK: {
        key: "JS_DEPENDENT_LINKS_TASK",
        priority: 21,
        title: t("issues.js_dependent_links_task.text_title"),
        problem: t("issues.js_dependent_links_task.text_problem"),
        solution: t("issues.js_dependent_links_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "origin_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_url_id", width: 300 },
                sortable: false,
            },
        ],
    },
    REPEATED_URLS_IN_SITEMAP_TASK: {
        key: "REPEATED_URLS_IN_SITEMAP_TASK",
        priority: 23,
        title: t("issues.repeated_urls_in_sitemap_task.text_title"),
        problem: t("issues.repeated_urls_in_sitemap_task.text_problem"),
        solution: t("issues.repeated_urls_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "origin_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: "Sitemaps",
                value: "sitemaps",
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_sitemaps_urls",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_sitemaps_ids", isArray: true, width: 300 },
            },
        ],
    },

    EXTERNAL_LINKS_NOT_FOUND_TASK: {
        key: "EXTERNAL_LINKS_NOT_FOUND_TASK",
        priority: 999,
        title: t("issues.external_links_not_found_task.text_title"),
        problem: t("issues.external_links_not_found_task.text_problem"),
        solution: t("issues.external_links_not_found_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_url_id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_reference_type"),
                value: "found_in",
                key: "referenceType",
                sortable: false,
            },
        ],
    },
    REPEATED_H1_TASK: {
        key: "REPEATED_H1_TASK",
        priority: 999,
        title: t("issues.repeated_h1_task.text_title"),
        problem: t("issues.repeated_h1_task.text_problem"),
        solution: t("issues.repeated_h1_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
        tutorialVideo: "https://www.youtube.com/embed/39Fun5yvfmM",
        tutorialVideoId: "39Fun5yvfmM",
    },
    SERVER_ERROR_IN_SITEMAP_TASK: {
        key: "SERVER_ERROR_IN_SITEMAP_TASK",
        priority: 999,
        title: t("issues.server_error_in_sitemap_task.text_title"),
        problem: t("issues.server_error_in_sitemap_task.text_problem"),
        solution: t("issues.server_error_in_sitemap_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
        ],
    },
    WRONG_HEADING_SEQUENCE_TASK: {
        key: "WRONG_HEADING_SEQUENCE_TASK",
        priority: 999,
        title: t("issues.wrong_heading_sequence_task.text_title"),
        problem: t("issues.wrong_heading_sequence_task.text_problem"),
        solution: t("issues.wrong_heading_sequence_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
        ],
    },
    ENCODED_URLS_TASK: {
        key: "ENCODED_URLS_TASK",
        priority: 999,
        title: t("issues.encoded_urls_task.text_title"),
        problem: t("issues.encoded_urls_task.text_problem"),
        solution: t("issues.encoded_urls_task.text_solution"),
        situation: "ALERT",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_internal"),
                value: "internal",
                key: "boolean",
                sortable: false,
            },
            {
                title: t("task.details.text_column_scanned"),
                value: "scanned",
                key: "boolean",
                sortable: false,
            },
        ],
    },
    OPTIMIZE_PNG_TO_JPG_TASK: {
        key: "OPTIMIZE_PNG_TO_JPG_TASK",
        priority: 999,
        title: t("issues.optimize_png_to_jpg_task.text_title"),
        problem: t("issues.optimize_png_to_jpg_task.text_problem"),
        solution: t("issues.optimize_png_to_jpg_task.text_solution"),
        situation: "OPPORTUNITY",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 700 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_compression_opportunities"),
                value: "compression_opportunities",
                sortable: false,
            },
        ],
    },
    SERVER_ERROR_TASK: {
        key: "SERVER_ERROR_TASK",
        priority: 999,
        title: t("issues.server_error_task.text_title"),
        problem: t("issues.server_error_task.text_problem"),
        solution: t("issues.server_error_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_url_id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_reference_type"),
                value: "found_in",
                key: "referenceType",
                sortable: false,
            },
        ],
    },
    NOT_FOUND_TASK: {
        key: "NOT_FOUND_TASK",
        priority: 999,
        title: t("issues.not_found_task.text_title"),
        problem: t("issues.not_found_task.text_problem"),
        solution: t("issues.not_found_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "origin_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
            {
                title: t("task.details.text_column_found_in_url"),
                value: "found_in_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "found_in_url_id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_reference_type"),
                value: "found_in",
                key: "referenceType",
                sortable: false,
            },
        ],
        tutorialVideo: "https://www.youtube.com/embed/xy_S5HAZA4w",
        tutorialVideoId: "xy_S5HAZA4w",
    },
    REDIRECT_TASK: {
        key: "REDIRECT_TASK",
        priority: 999,
        title: t("issues.redirect_task.text_title"),
        problem: t("issues.redirect_task.text_problem"),
        solution: t("issues.redirect_task.text_solution"),
        situation: "PROBLEM",
        headers: [
            {
                title: "URL",
                value: "url",
                key: "sendToDetailed",
                headerProps: { sendTo: "id", width: 300 },
                sortable: false,
            },
            {
                title: t("task.details.text_column_status_code"),
                value: "status_code",
                key: "statusCode",
                sortable: false,
            },
            {
                title: t("task.details.text_column_destination_url"),
                value: "destination_url",
                key: "sendToDetailed",
                headerProps: { sendTo: "destination_id", width: 300 },
                sortable: false,
            },
        ],
    },
}));
