<template>
    <notifications classes="vue-notification" position="top right" width="400" />
</template>

<style scoped lang="scss">
.vue-notification-group {
    z-index: 10000000000 !important;
}

:deep(.vue-notification) {
    margin: 6px 6px 0 0 !important;
    padding: 12px 16px !important;
    font-size: 14px !important;
    color: #ffffff !important;
    border-left: 0 !important;
    border-radius: 6px !important;
    background: #117a85dd !important;
    backdrop-filter: blur(4px);

    &.success {
        background: #0eb667aa !important;
        border: 1px solid #0eb667 !important;
    }

    &.warn {
        background: #eea12eaa !important;
        border: 1px solid #eea12e !important;
    }

    &.error {
        background: #be3927aa !important;
        border: 1px solid #be3927 !important;
    }
}
</style>
