import { authHttp } from "@/plugins/api/http-instances";
import { AxiosError } from "axios";
import { t } from "@/plugins/i18n";
import { sendFeedback } from "@/utils/send-feedback-helper";
import type {
    TDeleteUserParams,
    TLoginParams,
    TLoginWithGoogleCallbackParams,
    TRefreshTokenParams,
    TRegisterParams,
    TResetPasswordParams,
    TUpdateUserParams,
} from "./types/auth.types";
import { formatS3Path } from "@/utils/format-s3-path-helper";

export const authService = {
    registerUser,
    registerInvitedUser,
    login,
    loginWithGoogle,
    loginWithGoogleCallback,
    refreshToken,
    refetchCurrentUser,
    updateUser,
    deleteUser,
    recoverPassword,
    resetPassword,
    verifyEmailAlreadyInUse,
    resendEmailValidation,
    emailValidation,
};

async function registerUser({
    email,
    name,
    lastName,
    language,
    password,
    phoneNumber,
}: TRegisterParams) {
    try {
        const createdUser = await authHttp.registerUser({
            email,
            name,
            lastName,
            language,
            password,
            phoneNumber,
        });

        return createdUser;
    } catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.data?.message === "TOKEN_USED") {
                sendFeedback({ text: t("auth.register.err_token_used") }).error();
            } else {
                sendFeedback({ text: t("auth.register.err_failed_user_register") }).error();
            }
        }
        console.error(err);
    }
}

async function registerInvitedUser({
    email,
    name,
    lastName,
    language,
    password,
    phoneNumber,
    state,
}: TRegisterParams) {
    try {
        const createdUser = await authHttp.registerInvitedUser({
            email,
            name,
            lastName,
            language,
            password,
            phoneNumber,
            state,
        });

        return createdUser;
    } catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.data?.message === "TOKEN_USED") {
                sendFeedback({ text: t("auth.register.err_token_used") }).error();
            } else {
                sendFeedback({ text: t("auth.register.err_failed_user_register") }).error();
            }
        }
        console.error(err);
    }
}

async function login({ email, password }: TLoginParams) {
    try {
        const loggedUser = await authHttp.login({ email, password });

        localStorage.setItem(
            "user",
            JSON.stringify({
                ...loggedUser,
                token: undefined,
                refresh_token: undefined,
            }),
        );

        localStorage.setItem("token", loggedUser.token);
        localStorage.setItem("refresh_token", loggedUser.refresh_token);

        return loggedUser;
    } catch (err) {
        sendFeedback({ text: t("auth.login.err_cannot_login") }).error();

        console.error(err);
        throw err;
    }
}

async function loginWithGoogle() {
    try {
        const url = await authHttp.loginWithGoogle();

        return url;
    } catch (err) {
        sendFeedback({ text: t("auth.login.err_login_with_google") }).error();

        console.error(err);
        throw err;
    }
}

async function loginWithGoogleCallback(data: TLoginWithGoogleCallbackParams) {
    try {
        const loggedUser = await authHttp.loginWithGoogleCallback(data);

        localStorage.setItem(
            "user",
            JSON.stringify({
                ...loggedUser,
                token: undefined,
                refresh_token: undefined,
            }),
        );

        localStorage.setItem("token", loggedUser.token);
        localStorage.setItem("refresh_token", loggedUser.refresh_token);

        return loggedUser;
    } catch (err) {
        sendFeedback({ text: t("auth.login.err_login_with_google") }).error();

        console.error(err);
    }
}

async function refreshToken({ userId, currentRefreshToken }: TRefreshTokenParams) {
    try {
        const { token, refresh_token } = await authHttp.refreshToken({
            userId,
            currentRefreshToken,
        });

        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);

        return {
            token,
            refresh_token,
        };
    } catch (err) {
        sendFeedback({ text: t("auth.login.err_login_expired") }).error();

        console.error(err);
        throw err;
    }
}

async function refetchCurrentUser() {
    try {
        const user = await authHttp.refetchCurrentUser();

        if (user.attachments.length) {
            const mainAttachment = user.attachments.find(
                (attachment) => attachment.type === "PROFILE_IMAGE",
            );

            if (mainAttachment) {
                // add query timestamp to force no browser caching image
                user.image_url = formatS3Path(mainAttachment.key);
            }
        }

        if (user.workspaces[0]) {
            const userProjects = user.workspaces[0].projects;

            for (const project of userProjects) {
                const mainAttachment = project.attachments.find(
                    (attachment) => attachment.type === "PROFILE_IMAGE",
                );

                if (mainAttachment) {
                    project.image_url = formatS3Path(mainAttachment.key);
                }
            }

            userProjects.sort((a, b) => a.id - b.id);
        }

        localStorage.setItem("user", JSON.stringify(user));

        return user;
    } catch (err) {
        sendFeedback({ text: t("dashboard.projects.err_refetch_user") }).error();

        console.error(err);
        throw err;
    }
}

async function updateUser({
    name,
    lastName,
    email,
    language,
    password,
    currentPassword,
    phoneNumber,
}: TUpdateUserParams) {
    try {
        const user = await authHttp.updateUser({
            name,
            lastName,
            language,
            password,
            currentPassword,
            phoneNumber,
        });

        sendFeedback({ text: t("user.settings.text_success_update_profile") }).success();

        return user;
    } catch (err) {
        let message = t("user.settings.err_update_profile");

        const isSocialLogin = email?.includes("@liveseo.com.br") || email?.includes("@gmail.com");

        if (password && isSocialLogin) {
            message = t("user.settings.err_cannot_update_social_login_pwd");
        } else if (password) {
            message = t("user.settings.err_update_password");
        }

        sendFeedback({ text: message }).error();

        console.error(err);
    }
}

async function deleteUser({ userId, removedBy }: TDeleteUserParams) {
    try {
        const isDeleted = await authHttp.deleteUser({ userId, removedBy });

        return isDeleted;
    } catch (err) {
        sendFeedback({ text: t("user.settings.err_delete_user") }).error();

        console.error(err);
    }
}

async function recoverPassword(email: string) {
    try {
        const isRecoverSuccess = await authHttp.recoverPassword(email);

        return isRecoverSuccess;
    } catch (err) {
        sendFeedback({ text: t("auth.recover.text_recover_submit_error") }).error();

        console.error(err);
    }
}

async function resetPassword({ token, password }: TResetPasswordParams) {
    try {
        const isResetSuccess = await authHttp.resetPassword({ token, password });

        return isResetSuccess;
    } catch (err) {
        sendFeedback({ text: t("auth.update_password.text_submit_failed") }).error();

        console.error(err);
        throw err;
    }
}

async function verifyEmailAlreadyInUse(email: string) {
    try {
        const { inUse } = await authHttp.verifyEmailAlreadyInUse(email);
        return inUse;
    } catch (err) {
        sendFeedback({ text: t("auth.register.err_validate_email") }).error();

        console.error(err);
    }
}

async function resendEmailValidation(userId: number): Promise<boolean> {
    try {
        const isResentEmail = await authHttp.resendEmailValidation(userId);
        sendFeedback({ text: t("user.settings.email_validation_resent_success") }).success();

        return isResentEmail;
    } catch (err) {
        sendFeedback({ text: t("user.settings.email_validation_resent_error") }).error();

        console.error(err);
        return false
    }
}

async function emailValidation(token: string): Promise<boolean> {
    try {
        const isValid = await authHttp.emailValidation(token);
        sendFeedback({ text: t("auth.register.email_validation_success") }).success();
        return isValid;
    } catch (err) {
        if (err instanceof AxiosError) {
            if (err.response.data.message == "Email already validated.") {
                sendFeedback({
                    text: t("auth.register.email_validation_already_validated"),
                }).success();
                return true;
            }
        }
        sendFeedback({ text: t("auth.register.email_validation_error") }).error();

        console.error(err);
        return false;
    }
}
