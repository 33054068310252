export function verifyIssueRouteName(issueId: string): string | undefined {
    if (issueId.includes("_TASK")) {
        let issueName = "";
        issueName = issueId.replace("_TASK", "").toLowerCase();
        issueName = issueName.replace(/_/g, "-");
        return issueName;
    }

    return issueId;
}
