import { RouteRecordRaw } from "vue-router";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

export const tutorialRoutes: RouteRecordRaw[] = [
    {
        path: "tutorial",
        component: EmptyRouterView,
        meta: {
            title: "base.breadcrumb.text_tutorial",
        },
        children: [
            {
                path: "",
                name: "TutorialList",
                component: () => import("@/modules/tutorial/views/tutorial-list.vue"),
                meta: {
                    title: "base.breadcrumb.text_tutorial",
                    hideBreadcrumb: true,
                },
            },
        ],
    },
];
