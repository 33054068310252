<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="24" viewBox="0 0 86 24" fill="none">
        <g id="Wake-logo-sobrebranco 1" clip-path="url(#clip0_1825_1192)">
            <path
                id="Vector"
                d="M54.6855 0.0708008H49.276V23.4406H54.6855V0.0708008Z"
                fill="#1A1A1A"
            />
            <path
                id="Vector_2"
                d="M64.8881 17.5114V23.5511L49.3071 14.7833V8.74533L64.8881 17.5114Z"
                fill="#1A1A1A"
            />
            <path
                id="Vector_3"
                d="M49.276 14.5951V20.8558L64.9831 12.0881V5.82907L49.276 14.5951Z"
                fill="#C505F2"
            />
            <path
                id="Vector_4"
                d="M21.7088 5.09354L18.884 15.0596L16.0852 5.18505L16.1111 5.09354H16.0576H10.9485H10.8967L10.9226 5.18505L8.12375 15.0596L5.299 5.09354H0.136414L2.50879 13.5332L2.53296 13.4538L5.33699 23.4406H5.82562H5.84634H10.6481H10.6688H10.9088L13.516 14.2481L16.0973 23.4406H16.5859H16.6049H21.4084H21.4291H21.6691L26.8697 5.09354H21.7088Z"
                fill="#1A1A1A"
            />
            <path
                id="Vector_5"
                d="M33.303 17.7013C34.2095 18.6406 35.3162 19.1102 36.6924 19.1102C38.0685 19.1102 39.1752 18.6406 40.08 17.6668C40.9865 16.6947 41.4561 15.5534 41.4561 14.2118C41.4561 12.8702 40.9865 11.7289 40.08 10.7896C39.1752 9.85036 38.0668 9.38072 36.6924 9.38072C35.3162 9.38072 34.2095 9.85036 33.303 10.7896C32.4311 11.7289 31.9942 12.8702 31.9942 14.2118C31.9925 15.5879 32.4293 16.7292 33.303 17.7013ZM41.4561 5.10561H46.4219V23.4389H41.4561V21.5586C39.8797 23.1367 37.9994 23.9068 35.7859 23.9068C33.1683 23.9068 30.9876 22.9675 29.2765 21.1217C27.5655 19.2432 26.7263 16.9278 26.7263 14.1427C26.7263 11.3905 27.5983 9.10964 29.3111 7.29842C31.0221 5.48719 33.2029 4.58072 35.7859 4.58072C38.0011 4.58072 39.8797 5.38705 41.4561 6.99626V5.10561Z"
                fill="#1A1A1A"
            />
            <path
                id="Vector_6"
                d="M71.5217 12.3004H80.179C79.6748 10.1871 78.1312 8.87829 75.9505 8.87829C73.8043 8.87829 72.0933 10.1525 71.5217 12.3004ZM85.0774 16.0576H71.5563C72.1934 18.373 73.8717 19.5143 76.5548 19.5143C78.6354 19.5143 80.5468 18.9117 82.2924 17.7358L84.373 21.3255C82.1249 23.0366 79.4417 23.8757 76.3217 23.8757C73.0671 23.8757 70.5842 22.9364 68.8386 21.0907C67.1275 19.2449 66.2556 16.964 66.2556 14.2463C66.2556 11.4613 67.1603 9.18045 68.9404 7.3675C70.7189 5.52174 73.0671 4.61526 75.9523 4.61526C78.6699 4.61526 80.8852 5.4544 82.6291 7.09814C84.373 8.74188 85.2466 10.9899 85.2466 13.8423C85.2449 14.4812 85.1793 15.2202 85.0774 16.0576Z"
                fill="#1A1A1A"
            />
        </g>
        <defs>
            <clipPath id="clip0_1825_1192"><rect width="86" height="24" fill="white" /></clipPath>
        </defs>
    </svg>
</template>
