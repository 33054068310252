import { AxiosInstance } from "axios";

import type {
    TCheckWorkspaceDomainOutput,
    TCheckDomainParams,
    TCreateWorkspaceParams,
    TUpdateWorkspaceParams,
    TWorkspace,
    TWorkspaceUser,
    TValidateInviteToken,
    TConfirmUserInvite,
    TInvitesSend,
    TDeleteInvite,
    TResendInvite,
    TAllWorkspacesInvites,
} from "./types/workspace.types";

export class WorkspaceHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async getWorkspaces(): Promise<TWorkspace[]> {
        const { data } = await this.httpClient.get("/workspaces");

        return data as TWorkspace[];
    }

    async createWorkspace({ name, usage }: TCreateWorkspaceParams): Promise<TWorkspace> {
        const { data } = await this.httpClient.post("/workspaces", {
            name,
            usage,
        });

        return data as TWorkspace;
    }

    async getWorkspaceUsers(workspaceId: number): Promise<TWorkspaceUser[]> {
        const { data } = await this.httpClient.get(`/workspaces/${workspaceId}/users`);

        return data as TWorkspaceUser[];
    }

    async updateWorkspace({
        workspaceId,
        name,
        usage,
    }: TUpdateWorkspaceParams): Promise<TWorkspace> {
        const { data } = await this.httpClient.put(`/workspaces/${workspaceId}`, {
            name,
            usage,
        });

        return data as TWorkspace;
    }

    async checkDomain({
        workspaceId,
        url,
    }: TCheckDomainParams): Promise<TCheckWorkspaceDomainOutput> {
        const { data } = await this.httpClient.get(`/workspaces/${workspaceId}/verify-url`, {
            params: {
                url,
            },
        });

        return data as TCheckWorkspaceDomainOutput;
    }

    async workspaceInviteCallback(state: string): Promise<void> {
        await this.httpClient.get(`/workspaces/invite/callback?state=${state}`);
    }

    async validateWorkspaceInviteToken(token: string): Promise<TValidateInviteToken> {
        const { data } = await this.httpClient.get(
            `/workspaces/workspace_id/users/invite/validate?id=${token}`,
            {
                headers: { NeedAuth: false },
            },
        );

        return data as TValidateInviteToken;
    }

    async addWorkspaceInvitedUser(
        workspaceId: number,
        params: {
            language?: string;
            jwt?: string;
            name?: string;
            lastName?: string;
            password?: string;
            email: string;
            phoneNumber?: string;
        },
    ): Promise<TConfirmUserInvite> {
        const { data } = await this.httpClient.post(
            `/workspaces/${workspaceId}/users/invite/callback`,
            params,
            {
                headers: { NeedAuth: false },
            },
        );
        return data as TConfirmUserInvite;
    }

    async sendWorkspaceInvites(workspaceId: number, emails: Array<string>): Promise<TInvitesSend> {
        const { data } = await this.httpClient.post(`/workspaces/${workspaceId}/users/invite`, {
            acceptType: "EMAIL",
            emails: emails,
        });

        return data as TInvitesSend;
    }

    async getAllWorkspaceInvites(
        workspaceId: number,
        page = 1,
        limit = 100,
    ): Promise<TAllWorkspacesInvites> {
        const { data } = await this.httpClient.get(`/workspaces/${workspaceId}/users/invites`, {
            params: {
                limit: limit,
                page: page,
            },
        });

        return data as TAllWorkspacesInvites;
    }

    async deleteWorkspaceInvite(workspaceId: number, inviteId: number): Promise<TDeleteInvite> {
        const { data } = await this.httpClient.delete(
            `/workspaces/${workspaceId}/users/invite/${inviteId}`,
        );

        return data as TDeleteInvite;
    }

    async resendInvite(workspaceId: number, inviteId: string): Promise<TResendInvite> {
        const { data } = await this.httpClient.post(
            `/workspaces/${workspaceId}/users/invite/resend`,
            {
                id: inviteId,
            },
        );

        return data as TResendInvite;
    }

    async removeWorkspaceUser(workspaceId: number, userId: number): Promise<void> {
        await this.httpClient.delete(`/workspaces/${workspaceId}/users/${userId}`);
    }
}
