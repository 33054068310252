import "@/styles/global.scss";
import { createApp } from "vue";
import App from "./app.vue";

import { registerPlugins } from "@/plugins";

const app = createApp(App);

registerPlugins(app);

app.mount("#app");

// This is a temp fix for the post deployment issue
// that causes the app to crash when user in the old version of the app
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener("vite:preloadError", (_) => {
    window.location.reload();
});
