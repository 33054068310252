import { Router } from "vue-router";

import { useAuthStore } from "@/modules/base/auth/auth.store";

export function missingUserPlanMiddleware(router: Router) {
    router.beforeEach(async (to, from, next) => {
        const authStore = useAuthStore();
        let user = authStore.user;
        let missingUserPlan = user && user.user_plan && !Object.keys(user.user_plan).length;

        if (missingUserPlan) {
            user = await authStore.refetchCurrentUser();
            missingUserPlan = user && user.user_plan && !Object.keys(user.user_plan).length;
        }

        if (missingUserPlan && to.hash != "#plans") {
            return next({ name: "Register", hash: "#plans" });
        }

        if (!authStore.isLoggedIn && (from.name == "Register" || to.name == "Register")) {
            return next();
        }

        return next();
    });
}
