<template>
    <svg
        version="1.1"
        viewBox="0 0 1378 1600"
        width="40"
        height="40"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            transform="translate(1127)"
            d="m0 0h11l9 2 11 4 11 8 7 9 5 14 2 9v26l-4 21-6 21-5 13-7 15-9 17-10 17-9 13-8 12-9 13-13 19-8 12-8 11-10 15-8 11-13 19-7 9-24 33-10 14-13 18-11 15-8 11-10 14-8 11-12 17-11 15-7 10-8 11-7 10-13 18-8 12-12 16-13 18-11 15-10 14-11 15-13 19-8 11-11 16-18 27-11 17-9 14-6 11-4 6-14 28-5 12-2 9v17l4 16 8 16 10 14 8 10 13 16 9 10 7 8 15 15 5 6 39 39 6 5 11 11v2l4 2 14 14 6 5 19 19 8 7 12 11 15 14 11 9 14 13 8 7 14 12 15 14 9 7 12 12 8 7 10 9 8 7 10 9 8 7 12 11 8 7 10 9 8 7 10 9 11 9 8 7 10 9 11 9 7 6v2l4 2 15 14 8 7 20 18 8 7 9 9 8 7 10 9 8 8 8 7 6 5 13 13 6 5v2l4 2 16 16 7 6 7 8 8 9 10 13 11 17 9 19 5 16 2 13-2 18-4 10-7 8-11 7-12 4-7 1h-20l-19-4-24-9-17-8-7-4-23-12-23-11-24-11-18-7-19-7-23-7-28-9-32-10-33-8-34-7-39-6-63-7-53-3-41-1h-25l-33 1-43 3-33 3-40 5-35 6-34 7-42 10-21 6-29 9-28 10-16 6-14 5-12 5-23 10-15 6-14 7-20 9-17 9-17 8-22 12-27 15-22 13-4 1-1 3-14 9-12 8-23 13-15 8-16 7-19 7-19 5-9 2-9 1h-17l-17-4-10-4-11-8-7-9-7-15-2-8-1-21 3-19 6-23 7-17 8-16 7-12 6-11 11-17 10-15 6-8 4-7 12-16 8-11 4-6 12-16 11-15 8-10 14-19 9-11 12-16 14-18 6-8 11-14 13-17 14-18 20-26 4-4 3-5 9-11 11-14 7-9 13-16 10-13 7-9 16-20 11-14 16-20 10-13 13-16 7-9 8-10 6-8 11-14 8-10 6-8 28-36 6-8 16-21 6-8 10-13 24-32 13-17 3-4-1-5-11-9-13-11-22-18-12-10-22-18-15-13-9-7-11-9-8-7-12-10-11-9-16-15-8-7-3-2v-2l-4-2-2-4-4-2-4-4v-2l-3-1-8-11-7-10-8-16-5-15-1-5v-20l3-11 8-15 10-10 10-7 15-7 18-4 37-4 44-7 40-8 27-7 23-7 43-16 10-5 27-12 32-16 20-11 11-6 15-9 19-12 18-12 10-7 14-10 9-7 12-9 11-9h2v-2l9-7 11-10 9-7 9-9 8-7 3-3h2l1-3 8-7 3-3h2l2-4 8-7 5-5 7-8 5-4 5-5 12-13 8-8 7-8 7-7 9-11 10-11 12-17 9-13 11-15 12-17 8-11 10-13 12-16 9-11 12-13 12-12 8-7 9-7 14-10 16-9 13-5 13-4z"
            fill="#FE4227"
        />
        <path
            transform="translate(743,800)"
            d="m0 0h2l8 16 10 14 8 10 13 16 9 10 7 8 15 15 5 6 39 39 6 5 11 11v2l4 2 14 14 6 5 19 19 8 7 12 11 15 14 11 9 14 13 8 7 14 12 15 14 9 7 12 12 8 7 10 9 8 7 10 9 8 7 12 11 8 7 10 9 8 7 10 9 11 9 8 7 10 9 11 9 7 6v2l4 2 15 14 8 7 20 18 8 7 9 9 8 7 10 9 8 8 8 7 6 5 13 13 6 5v2l4 2 16 16 7 6 7 8 8 9 10 13 11 17 9 19 5 16 2 13-2 18-4 10-7 8-11 7-12 4-7 1h-20l-19-4-24-9-17-8-7-4-23-12-23-11-24-11-18-7-19-7-23-7-28-9-32-10-33-8-34-7-39-6-63-7-53-3-41-1h-25l-33 1-43 3-33 3-40 5-35 6-34 7-42 10-9 3-3-1 10-5-1-2 14-8 8-6 7-7 11-8 12-13 4-10 9-12 4-7 4-6 4-5 5-12 4-7 3-8 2-3v-6l3-1 2-6 3-5 2-8 4-9 7-13 4-4 6-17 4-8 5-15 1-10 8-15v-8l2-6 4-23 3-12 5-14 5-15 2-22 4-11 2-8 4-15 2-2 4-25 4-15 2-18 3-4 4-25 2-7v-7l-1-4 5-26 2-2 2-17 2-4 1-7 4-6 5-9 3-5h2l1-4 3-7h2l1-4 2-5h2l-1-5v-9l5-13z"
            fill="#FE8C21"
        />
        <path
            transform="translate(645,429)"
            d="m0 0 3 1-9 7-4 4-1 5-2 2 1 6 2 4v2l5 3-1 21 1 2v10l-3 14-1 25-5 21-4 15-3 21v23l3 21 1 8v8l-10 16-7 9-12 17-8 11-7 10-10 14-9 12-11 15-6 8-10 14-18 24-4-5-10-8-13-11-22-18-12-10-22-18-15-13-9-7-11-9-8-7-12-10-11-9-16-15-8-7-3-2v-2l-4-2-2-4-4-2-4-4v-2l-3-1-8-11-7-10-8-16-5-15-1-5v-20l3-11 8-15 10-10 10-7 15-7 18-4 37-4 44-7 40-8 27-7 23-7 43-16 10-5 27-12 11-5 12-2z"
            fill="#FE8C21"
        />
        <path
            transform="translate(1075,1211)"
            d="m0 0 13 6 7 2 6 4 4 4 17 9 10 2 6 5 9 2 10 9 7 6 9 5 6 7 9 7 10 9 10 8 5 2 8 7 11 8 3 2 7 2 10 6 13 10 14 10 12 9 9 6 3 3 9 4 12 8 12 10 9 12 9 8 7 9 7 13v2h2l5 18 1 12 1 1v9l-4 13-8 10-11 7-12 4-7 1h-20l-19-4-24-9-17-8-7-4-23-12-23-11-24-11-18-7-19-7-23-7-28-9-32-10-33-8v-2l-5-2-14-7-25-9-10-5-11-3-11-4 1-3 5-3 7-8 9-4 10-6 9-4 9-6 17-17 3-8 2-9 4-8 2-10 2-3 1-13 4-1-1-8 4-6 1-3h2v-16l1-9 3-3 12-3 7 3 9-2z"
            fill="#FEAD20"
        />
        <path
            transform="translate(1127)"
            d="m0 0h11l9 2 11 4 11 8 7 9 4 10-1 4-5-12-7-9-11-7-12-4h-18l-10 3-16 6-10 5-11 7-11 9-8 7-14 14-6 8h-2l-2 5-5 6-9 12-14 19-14 21-7 10-9 13-3 5-3 3-5 9-7 10-10 15-7 11-6 9-7 10-21 33-9 13-16 24-13 20-9 13-18 27-7 10-18 27-7 10-18 27-22 32-15 22-8 11-9 14-11 15-7 11-8 11-13 19-11 15-11 16-9 12-12 17-18 24-14 19-18 24-28 38-9 12-8 11-13 18-6 8-14 19-12 16-13 18-9 12-12 17-15 20-10 13-9 12-30 39-8 10-21 27-10 13-13 16-21 27-20 25-11 14-7 9-9 11-11 14-10 13-28 35-14 18-3 3-3 5-12 15-40 52-4 5-11 14-12 16-8 10-6 8-10 13-9 12-10 13-24 33-12 17-7 10-24 36-14 24-8 15-10 25-6 25v28l2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8-1-21 3-19 6-23 7-17 8-16 7-12 6-11 11-17 10-15 6-8 4-7 12-16 8-11 4-6 12-16 11-15 8-10 14-19 9-11 12-16 14-18 6-8 11-14 13-17 14-18 20-26 4-4 3-5 9-11 11-14 7-9 13-16 10-13 7-9 16-20 11-14 16-20 10-13 13-16 7-9 8-10 6-8 11-14 8-10 6-8 28-36 6-8 16-21 6-8 10-13 24-32 13-17 20-26 10-14 6-8 11-15 10-14 6-8 7-10 10-14 14-19 7-10 10-15 1-2v-13l-2-13-2-11v-23l3-21 4-15 5-21 1-25 3-14v-10l-1-2 1-21-5-3-1-5-2-3 1-5 4-8h2l2-4 7-5-11 3-8 1-4 1 3-3 16-8 33-18 15-9 19-12 18-12 10-7 14-10 9-7 12-9 11-9h2v-2l9-7 11-10 9-7 9-9 8-7 3-3h2l1-3 8-7 3-3h2l2-4 8-7 5-5 7-8 5-4 5-5 12-13 8-8 7-8 7-7 9-11 10-11 12-17 9-13 11-15 12-17 8-11 10-13 12-16 9-11 12-13 12-12 8-7 9-7 14-10 16-9 13-5 13-4z"
            fill="#FF5649"
        />
        <path
            transform="translate(441,502)"
            d="m0 0 6 1-5 6-3 1v2l-4 1-2 5-6 1v6l-5 7h-2l-2 11-2 8 1 8 3 5 3 7 4 5 4 7 1 7 6 4 10 19 3 4 3 6 3 3 3 13 5 5 1 5 5 2 5 8 3 13 5 9 5 3 6 8 8 6 8 12v2l6 2 6 8 3 5 8 3 5 4h5l3 3h6l8 6-2 6-7 10-6 8-8 11-18 24-4-5-10-8-13-11-22-18-12-10-22-18-15-13-9-7-11-9-8-7-12-10-11-9-16-15-8-7-3-2v-2l-4-2-2-4-4-2-4-4v-2l-3-1-8-11-7-10-8-16-5-15-1-5v-20l3-11 8-15 10-10 10-7 5-2 1 1 33-4 17-3 38-3z"
            fill="#FEAD20"
        />
        <path
            transform="translate(1142,1362)"
            d="m0 0h13l10 2 20-1 5 2 15 2 14 3 12 5 19 6 15 6 16 8 9 3 10 6 16 8 11 6 19 12 5 4 4 6 4 5 2 7 3 5 1 12 2-1 2 2 3-4 3-9 2 1v9l-4 13-8 10-11 7-12 4-7 1h-20l-19-4-24-9-17-8-7-4-23-12-23-11-24-11-18-7-9-4 2-1 7 1-8-3-20-7-11-4-28-11-8-7-1-3 3-3 13-4v-4l-9-6-4-3-1-5 2-4 7-3 23-2z"
            fill="#FEBA25"
        />
        <path
            transform="translate(771,355)"
            d="m0 0 7 1 2 2v8l-5 13-3 5-9 21-16 36-6 14-12 27-14 32-4 11-4 15-2 2-1 4h-2l-2 4-2 5-4 4-6 9-1 3h-2v3l-3 3-1 3h-2l-2 6-4 4h-3l2-7-1-3 4-18-2 5-2-2-3 8v3l2-1-1 6-3 3-3 11-3 9-2 1-3 10-7 10-13 19-6 8h-1l-1-13-2-13-2-11v-23l3-21 4-15 5-21 1-25 3-14v-10l-1-2 1-21-5-3-1-5-2-3 1-5 4-8 6-2 7 1 5-1v4l10-1 5 5 3 5 1-5-1-6 6 2 3 3 1 4v-9l-2-7-1-2 3-1h7v-2l-4-1-1-3 1-2h3l-1-4-4-4 1-4 10-7 14-9 15-10 5-3 19-12 11-6 6-4z"
            fill="#FE4227"
        />
        <path
            transform="translate(1171,114)"
            d="m0 0 1 3-11 25-9 17-10 17-9 13-8 12-9 13-13 19-8 12-8 11-10 15-8 11-13 19-7 9-24 33-10 14-13 18-11 15-8 11-10 14-8 11-12 17-11 15-7 10-8 11-7 10-13 18-8 12-12 16-13 18-11 15-10 14-11 15-13 19-8 11-11 16-18 27-11 17-9 14-6 11-4 6-14 28-5 12-2 9v17l3 13-1 5-5 12-3 7-14 2-1 3h-2v2h-2v2l-6 2-2-1-1-7h-2l-1 9-2 2-1 6-3 1v-13l-1-8h-19l-9-3 1-7 6-11 15-22 9-13 10-14 13-18 8-11 11-15 6-8 7-10 11-15 4-5 16-22 30-40 7-10 6-7 7-10 8-10 21-28 8-10 8-11 9-12 13-17 8-11 9-11 6-9 6-7 8-11 9-12 10-13 8-11 7-9 8-11 1-2h2l2-4 10-14 10-13 11-15 10-13 8-11 10-13 4-6 9-11 6-9 14-19 10-14 3-5h2l2-4 4-6 9-12 8-11 7-10 12-18 7-11 6-9 6-11 8-14z"
            fill="#FE574A"
        />
        <path
            transform="translate(842,290)"
            d="m0 0 3 1-2 9-8 16-10 19-6 10-4 10-6 12-3 9 5-4 9-12 11-15 12-18 10-16 9-14 4-6 1 2-6 10-5 9-11 18-11 17-12 19-15 23-9 14-13 20-15 23-10 15-13 20-38 57h-1l1-8 4-13 11-26 17-38 5-12 9-20 12-27 5-13 3-4 4-11v-8l-6-2-9 3-14 9-11 6-11 7-9 6-11 7-14 9-10 7 1 3 4 3-1 5h-3l1 5 3 1v2l-9 2 2 2 1 6v9l-2 1-1-7-6-2-2-1 2 8-2 5-2-3-4-7-2-1h-10v-4l-5 2-9-1-2-1 9-7-11 3-8 1-4 1 3-3 16-8 33-18 15-9 19-12 18-12 10-7 14-10 9-7 12-9 7-6 7-3 4-2v-2l5-4h2v-2l4-2 3-3 14-11 11-9z"
            fill="#FE5649"
        />
        <path
            transform="translate(1116,2)"
            d="m0 0 4 1-8 3-12 4-17 9-15 10-12 9-7 6-4 5-13 13-6 8-8 10-18 26-10 15-7 11-6 8-3 5-6 9-15 23-17 26-22 34-13 20-15 23-20 30-13 20-7 10-7 11-20 30-7 10-34 51-15 22-10 15-11 16-4 5-4 7-11 16-9 13-11 16-7 10-9 13-8 11-11 15v-3l5-9h2l2-5 2-4h2l1-5 3-1v-3h2l1-4 8-12h2l1-5 3-4h2l1-5 5-6 30-45 4-5 4-7 13-20 10-15 9-14 15-23 11-17 13-20 21-33 12-19 7-11 6-11v-1l-9 14-10 16-8 12-11 16-9 12-9 11-3 1 1-6 6-14 10-20 6-10 10-19 5-13v-3l-9 3-6 4-15 12-9 7h-2l-1 3-4 1v2l-5 4h-2v2l-7 5-2-1 4-2v-2l9-7 11-10 9-7 9-9 8-7 3-3h2l1-3 8-7 3-3h2l2-4 8-7 5-5 7-8 5-4 5-5 12-13 8-8 7-8 7-7 9-11 10-11 12-17 9-13 11-15 12-17 8-11 10-13 12-16 9-11 12-13 12-12 8-7 9-7 14-10 16-9 13-5z"
            fill="#FE7962"
        />
        <path
            transform="translate(726,821)"
            d="m0 0h8l1 14h-2l-1 5-2 4h-2l-1 4-3 7-2 1-10 18-1 1-2 10-1 1-2 17-2 2-6 31-4 7-2 5-4 4-2 7-8 11-2 6-11 13-6 7-1-4-4 2-5 1 3-12 5-25 6-16 8-29 2-6 3 1 2-10 1-1v-2h3l1-8 4-4 6-26 2-11 2-17 2 1v11h2v-6l3-5 1-6h2l2 6h7v-2h2v-2l5-4z"
            fill="#FE552F"
        />
        <path
            transform="translate(519,802)"
            d="m0 0v3l-2 3 1 2-13 18-9 12-12 17-15 20-10 13-9 12-30 39-8 10-21 27-10 13-13 16-21 27-20 25-11 14-7 9-9 11-11 14-10 13-28 35-14 18-3 3-3 5-12 15-40 52-4 5-11 14-12 16-8 10-6 8-10 13-9 12-10 13-24 33-12 17-7 10-24 36-14 24-8 15-10 25-6 25v28l2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8-1-21 3-19 6-23 7-17 8-16 7-12 6-11 11-17 10-15 6-8 4-7 12-16 8-11 4-6 12-16 11-15 8-10 14-19 9-11 12-16 14-18 6-8 11-14 13-17 14-18 20-26 4-4 3-5 9-11 11-14 7-9 13-16 10-13 7-9 16-20 11-14 16-20 10-13 13-16 7-9 8-10 6-8 11-14 8-10 6-8 28-36 6-8 16-21 6-8 10-13 24-32 13-17z"
            fill="#FE594D"
        />
        <path
            transform="translate(926,477)"
            d="m0 0 2 1-8 11-13 18-8 12-12 16-13 18-11 15-10 14-11 15-13 19-8 11-11 16-18 27-11 17-9 14-6 11-4 6-14 28-5 12-2 9v17l3 13-1 5-5 12-3 7-7 1-5-1-3-3-1-5h-2v-3h-2l-4-9-4-4 1-8 6-11 7-10 11-18 8-11 3-6 9-13 10-15 7-10 8-12 8-11 9-13 12-17 1-2h2l2-4 6-9 13-18 10-14 8-11 6-8 7-10 11-15 7-10 10-13 7-10 13-18 8-11z"
            fill="#FE6C61"
        />
        <path
            transform="translate(276,1477)"
            d="m0 0h2l-1 12 13-1 19-7 3 1-16 8-29 15-21 12-23 13-12 7h-2l-1 3-14 9-12 8-23 13-15 8-16 7-19 7-9 2 1-2 15-6 8-4 16-10 4-4 11-8 14-12 4-4h2l2-4 12-9 13-8 17-10 12-8 14-8 10-7 16-9z"
            fill="#FE584A"
        />
        <path
            transform="translate(1116,2)"
            d="m0 0 4 1-8 3-12 4-17 9-15 10-12 9-7 6-4 5-13 13-6 8-8 10-18 26-10 15-7 11-6 8-3 5-6 9-15 23-17 26-22 34-13 20-15 23-20 30-13 20-7 10-7 11-20 30-7 10-34 51-15 22-10 15-11 16-4 5-4 7-11 16-9 13-11 16-7 10-9 13-8 11-11 15v-3l5-9h2l2-5 2-4h2l1-5 3-1v-3h2l1-4 8-12h2l1-5 3-4h2l1-5 5-6 30-45 4-5 2-4 4-2 22-33 8-11 9-14 28-42 7-11 7-10 9-14 10-15 7-11 13-20 32-49 12-19 7-11 9-14 4-6 11-17 10-16 18-27 11-17 14-21 7-10 1-3 5-5 1-3 2-1 1-5 9-11 19-19 8-7 9-7 14-10 16-9 13-5z"
            fill="#FE6B61"
        />
        <path
            transform="translate(867,261)"
            d="m0 0h4l-1 6-15 31-1 4 5-3 16-24 3-2-2 5-6 12-3 3-1-1-6 10-7 11-15 23-11 16-9 12-9 11-3 1 1-6 6-14 10-20 6-10 10-19 5-13v-3l-9 3-6 4-15 12-9 7h-2l-1 3-4 1v2l-5 4h-2v2l-7 5-2-1 4-2v-2l9-7 11-10 9-7 9-9 8-7 5-4 8-6 15-14z"
            fill="#FE6B61"
        />
        <path
            transform="translate(232,1176)"
            d="m0 0 1 3-8 9-9 12-21 27-10 13-14 18-10 13-15 20-4 5-13 17-20 26-24 33-12 17-7 10-24 36-14 24-8 15-10 25-6 25v28l2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8-1-21 3-19 6-23 7-17 2-2 2-3 15-27 3-6h2l2-4 10-16 9-13 3-6h2l2-5 6-8 13-18 5-7 1-3 5-5 14-19 2-4 2-1 2-5 5-4 4-6 4-5 10-13 9-12 7-9 11-14 7-9 2-5 5-4 3-6 5-5 16-20 1-3 7-7 2-5 5-5 3-5z"
            fill="#FE564A"
        />
        <path
            transform="translate(691,401)"
            d="m0 0 3 1-4 4-6 4 1 3 4 3-1 5h-3l1 5 3 1v2l-9 2 2 2 1 6v9l-2 1-1-7-6-2-2-1 2 8-2 5-2-3-4-7-2-1h-10v-4l-5 2-9-1-2-1 9-7-11 3-7 1 4-3 16-8 12-7 16-8 10-6z"
            fill="#FE5630"
        />
        <path
            transform="translate(755,727)"
            d="m0 0 1 3-8 16-5 12-2 9v17l3 13-1 5-5 12-3 7-3 1-1-5-3-21v-16h-3l2-9 7-12 13-21z"
            fill="#FE7A62"
        />
        <path
            transform="translate(226,1178)"
            d="m0 0 2 1-3 4-4 7-5 4-2 5-5 6h-2l-1 4-8 10-10 13-3 1-1 4-4 5-3 2-2 5-21 27-12 16-8 10-6 8-7 9-3 2-2 5h-2l-1 4-18 24h-2l-1 4-13 19-4 5-9 13h-2l-1 5-6 8-10 15-7 11h-2l-1 5-12 21-6 12-1-4 8-16 8-14 12-19 10-15 8-11 4-7 12-16 8-11 4-6 12-16 11-15 8-10 14-19 9-11 12-16 14-18 6-8 11-14 13-17 14-18z"
            fill="#FE7C59"
        />
        <path
            transform="translate(845,338)"
            d="m0 0 1 2-7 11-20 30-7 10-34 51-15 22-10 15-11 16-4 5-4 7-11 16-9 13-11 16-7 10-9 13-8 11-11 15v-3l5-9h2l2-5 2-4h2l1-5 3-1v-3h2l1-4 8-12h2l1-5 3-4h2l1-5 5-6 30-45 4-5 2-4 4-2 22-33 8-11 9-14 28-42 7-11 7-10 9-14z"
            fill="#FF6960"
        />
        <path
            transform="translate(635,643)"
            d="m0 0 2 4-2 5-24 32-28 38-12 16-8 11-13 18-6 8-14 19-11 15-3-1 3-4 2-5 11-14 13-18 6-8 11-15 10-14 6-8 7-10 10-14 14-19 7-10 10-15 6-7z"
            fill="#FE5430"
        />
        <path
            transform="translate(89,1363)"
            d="m0 0 1 3-8 10-9 13-7 10-24 36-14 24-8 15-10 25-6 25v28l2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8-1-21 3-19 6-23 7-17 2-2 2-3 15-27 3-6h2l2-4 10-16 9-13 3-6h2l2-5 6-8 11-15z"
            fill="#FE574A"
        />
        <path
            transform="translate(357,1012)"
            d="m0 0 2 1-5 8-11 14-5 4-2 5-4 2-1 4-6 4 2-4 16-20 10-13zm-33 42v3l-2 1zm-3 4m-1 1 1 2-4 7-11 13-3 2-3 6-8 10-10 13-5 6-4 2 2-4 11-14 10-13 20-25zm-48 61m-1 1m-1 1v3l-3 6-12 15-1 2-5 2 2-4 13-16zm-22 28v3zm-2 3v3l-4 7-9 11-5 2 6-9 6-6 3-5z"
            fill="#FE7362"
        />
        <path
            transform="translate(926,477)"
            d="m0 0 2 1-8 11-13 18-8 12-12 16-13 18-11 15-10 14-11 15-13 19-16 22-2-2 6-9 13-18 10-14 8-11 6-8 7-10 11-15 7-10 10-13 7-10 13-18 8-11z"
            fill="#FE745C"
        />
        <path
            transform="translate(485,847)"
            d="m0 0 1 2-2 4-3 3-10 14-7 9-3 3-4 7-4 4-3 5-14 18-6 4 2-4 8-10 6-8 16-21 6-8 10-13zm-56 73v3l-5 5 2-4zm-6 8v3l-2 1zm-3 4m-1 1 1 4-6 7-3 2-2 5-9 11-5 5-3 2 2-4 11-14 7-9zm-28 36v3l-5 6-2 5-11 14-6 4 2-4 11-14 7-9zm-25 32v3l-3 2 2-4zm-4 5v3h-3z"
            fill="#FE8455"
        />
        <path
            transform="translate(226,1178)"
            d="m0 0 2 1-3 4-4 7-5 4-2 5-5 6h-2l-1 4-8 10-10 13-3 1-1 4-4 5-3 2-2 5-21 27-12 16-7 9v-3l2-5 9-11 12-16 14-18 6-8 11-14 13-17 14-18z"
            fill="#FE7D5A"
        />
        <path
            transform="translate(845,338)"
            d="m0 0 1 2-7 11-20 30-7 10-34 51-15 22-7 10h-2l2-4 16-24 8-11 9-14 28-42 7-11 7-10 9-14z"
            fill="#FF6B62"
        />
        <path
            transform="translate(674,558)"
            d="m0 0 2 4-4 18-1 2 2 1-3 7 3 1-5 8h-2v3l-3 3-7 10-1-2 3-11 3-4 5-15h2l1-7-2 1 1-7 3-8 2 2z"
            fill="#FE5530"
        />
        <path
            transform="translate(15,1474)"
            d="m0 0 2 1-8 22-1 9-4 18v28l2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8-1-21 3-19 6-23z"
            fill="#FE6C58"
        />
        <path
            transform="translate(716,381)"
            d="m0 0h2l-1 3-22 14-15 9-16 9-11 6-26 13-3-1 19-10 33-18 15-9 19-12z"
            fill="#FE8356"
        />
        <path
            transform="translate(1116,2)"
            d="m0 0 4 1-8 3-12 4-17 9-15 10-5 4-6 1 4-5 15-10 14-8 13-5z"
            fill="#FE7D59"
        />
        <path
            transform="translate(62,1398)"
            d="m0 0v3l-7 10-11 17-4 6h-2l-1 5-12 21-6 12-1-4 8-16 8-14 12-19 10-15 5-5z"
            fill="#FE7A5A"
        />
        <path
            transform="translate(275,1116)"
            d="m0 0v3l-2 1zm-3 4m-1 1m-1 1v3l-3 6-12 15-1 2-5 2 2-4 13-16zm-22 28v3zm-2 3v3l-4 7-9 11-5 2 6-9 6-6 3-5z"
            fill="#FE8157"
        />
        <path
            transform="translate(1127)"
            d="m0 0h11l9 2 11 4 11 8 7 9 4 10-1 4-5-12-7-9-11-7-4-2v-2l-13-2-16-1z"
            fill="#FE7454"
        />
        <path
            transform="translate(357,1012)"
            d="m0 0 2 1-5 8-11 14-5 4-2 5-4 2-1 4-6 4 2-4 16-20 10-13zm-33 42v3l-2 1zm-3 4m-1 1v3h-3z"
            fill="#FE7E59"
        />
        <path
            transform="translate(926,477)"
            d="m0 0 2 1-8 11-13 18-8 12-10 14-2-3 9-12 13-18 8-11z"
            fill="#FE775B"
        />
        <path
            transform="translate(303,551)"
            d="m0 0h1v8l-2 13v13l1 7-2-1-3-12v-20l2-4v2h2z"
            fill="#FEB424"
        />
        <path
            transform="translate(780,334)"
            d="m0 0 2 1-10 9-11 8-5 4-19 14-13 9-3 2-3-1 13-9 10-7 11-8 9-7 12-9z"
            fill="#FE7E58"
        />
        <path
            transform="translate(0,1531)"
            d="m0 0h3l1 21 2 8 4 9 5 8 9 10h-3l-5-5-6-7-7-15-2-8z"
            fill="#FE694B"
        />
        <path
            transform="translate(828,293)"
            d="m0 0 2 1-4 2zm-4 3 2 1-14 14-7 6h-2l-1 3-4 1v2l-5 4h-2v2l-7 5-2-1 4-2v-2l9-7 11-10 9-7z"
            fill="#FE8355"
        />
        <path
            transform="translate(226,1178)"
            d="m0 0 2 1-3 4-4 7-5 4-2 5-5 6h-2l-1 4-5 6-3-1 11-14 14-18z"
            fill="#FE7A5C"
        />
        <path
            transform="translate(1055,34)"
            d="m0 0 2 1-4 2zm-4 3 2 1h-3zm-3 2 2 1-3 4-2-1zm-5 5h2l-2 4-11 11-8 7v-3zm-21 22v3l-6 5 2-4z"
            fill="#FE8A50"
        />
        <path
            transform="translate(156,1271)"
            d="m0 0v3l-5 6-10 13-6 8v-3l2-5 9-11 7-9z"
            fill="#FE785E"
        />
        <path
            transform="translate(485,847)"
            d="m0 0 1 2-2 4-3 3-10 14-7 9-2-2 6-8 10-13z"
            fill="#FE8157"
        />
        <path
            transform="translate(716,381)"
            d="m0 0h2l-1 3-22 14-8 5-2-1 6-5 19-12z"
            fill="#FE765E"
        />
        <path
            transform="translate(1116,2)"
            d="m0 0 4 1-8 3-12 4-11 6-4-2 10-5 13-5z"
            fill="#FE7E57"
        />
        <path
            transform="translate(767,452)"
            d="m0 0v3l-10 15-8 11-2 1 2-5 10-16 5-7z"
            fill="#FE7363"
        />
        <path transform="translate(1171,114)" d="m0 0 1 3-11 25-5 9-2-1 2-5 8-16z" fill="#FE624E" />
        <path
            transform="translate(62,1398)"
            d="m0 0v3l-7 10-10 15-3 1 2-5 12-18 5-5z"
            fill="#FE755E"
        />
        <path transform="translate(1370,1428)" d="m0 0h2l5 17 1 13-3-1-1-12-4-15z" fill="#FE9121" />
        <path
            transform="translate(509,815)"
            d="m0 0 1 2-4 7-5 5-9 13-4 4-2-1 10-13 9-12z"
            fill="#FE8056"
        />
        <path transform="translate(826,616)" d="m0 0 1 3-6 8-8 11-2-2 6-9 7-9z" fill="#FE715F" />
        <path
            transform="translate(134,1297)"
            d="m0 0 2 1-6 10-5 6-4 5-4 4-2-1 8-10z"
            fill="#FE8059"
        />
        <path
            transform="translate(197,1215)"
            d="m0 0 3 1-6 8-7 9h-2l-1 4-2 2-1-3 12-15z"
            fill="#FE775F"
        />
        <path transform="translate(319,1062)" d="m0 0v3l-13 16-4 4-2-1 8-10 9-11z" fill="#FE8555" />
        <path
            transform="translate(339,1035)"
            d="m0 0 2 1-3 3-2 5-4 2-1 4-6 4 2-4 8-10zm-15 19v3l-2 1zm-3 4m-1 1v3h-3z"
            fill="#FE8753"
        />
        <path transform="translate(19,1472)" d="m0 0 1 2-10 25-2 1 2-9 6-16z" fill="#FF564A" />
        <path transform="translate(511,792)" d="m0 0 10 1 2 1-1 5-3 3-4-6z" fill="#FEB525" />
        <path
            transform="translate(115,1323)"
            d="m0 0h2l-1 3h-2l-1 4-12 16v-3l3-6 10-13z"
            fill="#FE755F"
        />
        <path
            transform="translate(6,1563)"
            d="m0 0 2 2 6 11 10 11h-3l-5-5-6-7-4-8z"
            fill="#FE6450"
        />
        <path transform="translate(244,1157)" d="m0 0v3l-8 10-5 5h-3l9-11z" fill="#FE7E59" />
        <path
            transform="translate(226,1178)"
            d="m0 0 2 1-3 4-4 7-5 4-3 4-2-1 12-15z"
            fill="#FE8852"
        />
        <path transform="translate(519,802)" d="m0 0v3l-2 3 1 2-5 7h-3l1-5z" fill="#FE664D" />
        <path transform="translate(1083,15)" d="m0 0h4v2l-10 6-4 3-2-1 5-6z" fill="#FE725F" />
        <path transform="translate(899,515)" d="m0 0 1 3-4 5-7 10-2-3 11-14z" fill="#FE7959" />
        <path transform="translate(145,1285)" d="m0 0v3l-10 13v-3l2-5 6-7z" fill="#FE745F" />
        <path transform="translate(1014,77)" d="m0 0v3h-2l-1 5-8 7v-3l8-10z" fill="#FE8954" />
        <path
            transform="translate(383,980)"
            d="m0 0h2l-2 4-10 13-3 2 2-4 8-10 2-4z"
            fill="#FE7062"
        />
        <path transform="translate(857,572)" d="m0 0 1 3-9 12-2-2 7-9z" fill="#FE725F" />
        <path transform="translate(461,878)" d="m0 0 2 1-6 10-3 3-3-1z" fill="#FE8355" />
        <path transform="translate(27,1453)" d="m0 0v3l-8 16-1-4 7-14z" fill="#FE755D" />
        <path transform="translate(8,1500)" d="m0 0 1 3-3 9-3 4 1-9 1-3h2z" fill="#FE5F53" />
        <path transform="translate(114,1587)" d="m0 0 4 2-15 5-3-1 12-5z" fill="#FE694F" />
        <path transform="translate(509,817)" d="m0 0 3 1-8 11h-2l2-5h2l2-5z" fill="#FF5641" />
        <path transform="translate(419,935)" d="m0 0v3l-5 6-4 4-2-1 9-11z" fill="#FE7F59" />
        <path transform="translate(471,867)" d="m0 0v3l-7 9-2-2 7-9z" fill="#FE785D" />
        <path transform="translate(809,310)" d="m0 0 1 3-5 4h-2l-1 3-3 1 2-4z" fill="#FE7D59" />
        <path transform="translate(319,1062)" d="m0 0v3l-8 10v-3l2-4 4-5z" fill="#FE7163" />
        <path transform="translate(300,1085)" d="m0 0 2 1-7 9-3-1z" fill="#FE7A5C" />
        <path transform="translate(887,531)" d="m0 0 1 3-6 8-2-1 2-4z" fill="#FE7060" />
        <path transform="translate(906,504)" d="m0 0 1 3-6 9-2-2z" fill="#FE735E" />
        <path transform="translate(134,1297)" d="m0 0 2 1-6 10-4 3 2-4 5-9z" fill="#FE7B5C" />
        <path transform="translate(1171,20)" d="m0 0 4 2 5 11-1 4-5-12z" fill="#FE674F" />
        <path transform="translate(78,1375)" d="m0 0v3l-5 7h-2l1-4 4-5z" fill="#FE755E" />
        <path transform="translate(656,417)" d="m0 0 4 1-11 6-3-1z" fill="#FE7A5E" />
        <path
            transform="translate(687,399)"
            d="m0 0 2 1h-3zm-3 2 2 1-4 4-6 1 3-3z"
            fill="#FE8355"
        />
        <path transform="translate(716,381)" d="m0 0h2l-1 3-6 4-4-1zm-11 7 2 1z" fill="#FE7F5A" />
    </svg>
</template>
