import { defineStore } from "pinia";

import { workspaceService } from "./workspace.service";
import { useAuthStore } from "../auth/auth.store";

import type {
    TCreateWorkspaceParams,
    TUpdateWorkspaceParams,
    TWorkspace,
    TWorkspaceUser,
} from "./types/workspace.types";

type TWorkspaceStoreState = {
    workspaces: TWorkspace[];
    currentWorkspace: TWorkspace | null;
    workspaceUsers: TWorkspaceUser[];
    isFetchingWorkspaces: boolean;
    isFetchingCurrentWorkspace: boolean;
};

export const useWorkspaceStore = defineStore("workspace", {
    state: (): TWorkspaceStoreState => ({
        workspaces: [],
        currentWorkspace: null,
        workspaceUsers: [],
        isFetchingWorkspaces: false,
        isFetchingCurrentWorkspace: false,
    }),

    actions: {
        async getWorkspaces() {
            if (this.isFetchingWorkspaces) return;

            this.isFetchingWorkspaces = true;
            this.isFetchingCurrentWorkspace = true;
            const workspaces = await workspaceService.getWorkspaces();

            if (workspaces[0]) {
                this.workspaces = workspaces;
                const workspaceIdLocalstorage = localStorage.getItem("currentWorkspaceId");
                if (
                    workspaceIdLocalstorage &&
                    workspaces.find(
                        (workspace) => workspace.id == parseInt(workspaceIdLocalstorage),
                    )
                ) {
                    this.setCurrentWorkspace(parseInt(workspaceIdLocalstorage));
                } else {
                    this.setCurrentWorkspace(workspaces[0].id);
                }
            }

            this.isFetchingWorkspaces = false;
            this.isFetchingCurrentWorkspace = false;

            return workspaces;
        },

        async createWorkspace({ name, usage }: TCreateWorkspaceParams) {
            const newWorkspace = await workspaceService.createWorkspace({
                name,
                usage,
            });

            if (newWorkspace) {
                const authStore = useAuthStore();

                await authStore.refetchCurrentUser();
                await this.getWorkspaces();

                this.setCurrentWorkspace(newWorkspace.id);

                return newWorkspace;
            }
        },

        async getWorkspaceUsers(workspaceId: number) {
            this.workspaceUsers = await workspaceService.getWorkspaceUsers(workspaceId);

            return this.workspaceUsers;
        },

        async update({
            workspaceId,
            name,
            usage,
            imageUrl,
        }: TUpdateWorkspaceParams & { imageUrl?: string }) {
            await workspaceService.updateWorkspace({
                workspaceId,
                name,
                usage,
            });
            const updatedWorkspace = this.workspaces.find(
                (workspace) => workspace.id == workspaceId,
            );

            if (!updatedWorkspace) return;

            updatedWorkspace.name = name;

            if (imageUrl) {
                updatedWorkspace.image_url = imageUrl;
            }
        },

        setCurrentWorkspace(workspaceId: number) {
            if (!workspaceId) {
                throw new Error("Missing workspace id.");
            }

            const currentWorkspace = this.workspaces.find(
                (workspace) => workspace.id == workspaceId,
            );

            if (currentWorkspace && Object.keys(currentWorkspace).length) {
                this.currentWorkspace = currentWorkspace;
                localStorage.setItem("currentWorkspaceId", workspaceId.toString());
            }

            return currentWorkspace;
        },

        clearWorkspace() {
            this.currentWorkspace = null;
        },
    },
});
