import { App } from "vue";
import notifications from "@kyvg/vue3-notification";

import { vuetify } from "./vuetify/vuetify";
import { pinia } from "./pinia";
import { router } from "./router/router";
import { i18n } from "./i18n";
import { vPhoneInput } from "./v-phone-input";

export function registerPlugins(app: App<Element>) {
    app.use(vuetify).use(pinia).use(i18n).use(router).use(notifications).use(vPhoneInput);
}
