import { RouteRecordRaw } from "vue-router";
import { useWorkspaceStore } from "../workspace/workspace.store";
import { workspaceService } from "../workspace/workspace.service";
import { useAuthStore } from "./auth.store";
import { authService } from "@/modules/base/auth/auth.service";
import { sendFeedback } from "@/utils/send-feedback-helper";

import type { TLoginWithGoogleCallbackParams } from "./types/auth.types";

import { t } from "@/plugins/i18n";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

export const authRoutes: RouteRecordRaw[] = [
    {
        path: "/login",
        name: "Login",
        component: () => import("@/modules/base/auth/views/login.vue"),
        meta: {
            metaTitle: "auth.login.text_title",
            requiresAuth: false,
        },
        beforeEnter: async (_to, _from, next) => {
            const authStore = useAuthStore();

            authStore.storeLoginIfAuthenticated();

            if (authStore.isLoggedIn) {
                return next({ name: "Dashboard" });
            }

            next();
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/modules/base/auth/views/register/index.vue"),
        meta: {
            metaTitle: "auth.register.text_title",
            requiresAuth: false,
        },
        beforeEnter: async (to, _from, next) => {
            const authStore = useAuthStore();
            const workspaceStore = useWorkspaceStore();

            const hasUserPlan =
                authStore.user?.user_plan && !!Object.keys(authStore.user.user_plan).length;

            if (authStore.isLoggedIn && hasUserPlan) {
                return next({ name: "Dashboard" });
            }

            try {
                const { authuser, code, hd, prompt, scope } = to.query;
                const isFromGAuth = authuser && code && hd && prompt && scope;

                if (isFromGAuth) {
                    const loginData = {
                        authuser,
                        code,
                        hd,
                        prompt,
                        scope,
                    } as TLoginWithGoogleCallbackParams;

                    await authStore.loginWithGoogleCallback(loginData);

                    await workspaceStore.getWorkspaces();
                    const hasWorkspaces = workspaceStore.workspaces.length;
                    if (!hasWorkspaces) {
                        return next();
                    }

                    return next({ name: "Dashboard" });
                }

                next();
            } catch {
                console.error("Failed on register.");
                return next({ name: "Login" });
            }
        },
    },
    {
        path: "/invite",
        name: "Invite",
        component: () => import("@/modules/base/auth/views/register/index.vue"),
        meta: {
            metaTitle: "auth.register.text_title",
            requiresAuth: false,
        },
        beforeEnter: async (to, _from, next) => {
            const authStore = useAuthStore();
            const workspaceStore = useWorkspaceStore();
            const useWorkspaceService = workspaceService;

            try {
                const isTokenUsed = await useWorkspaceService.validateInviteToken(
                    to.query.state as string,
                );
                const isEmailinUse = await authService.verifyEmailAlreadyInUse(isTokenUsed.email);

                if (isEmailinUse && isTokenUsed.isValid && isTokenUsed.status === "PENDING") {
                    await workspaceService.addWorkspaceInvitedUser(isTokenUsed.workspaceId, {
                        email: isTokenUsed.email,
                        jwt: to.query.state as string,
                    });
                    sendFeedback({
                        text: t("workspace.invite.workspace_invite_accepted"),
                    }).success();
                    return next({ name: "Login" });
                }
                if (!isTokenUsed.isValid && isTokenUsed.status === "ACCEPTED") {
                    if (authStore.isLoggedIn) {
                        await workspaceStore.getWorkspaces();
                        const hasWorkspaces = workspaceStore.workspaces.length;

                        if (hasWorkspaces) {
                            return next({ name: "Dashboard" });
                        }
                    } else {
                        to.params.email = isTokenUsed.email;
                        sendFeedback({ text: t("auth.login.err_invite_used") }).error();
                        return next({ name: "Login" });
                    }
                } else if (!isTokenUsed.isValid && isTokenUsed.status === "INVALID") {
                    return next({ name: "Login" });
                } else {
                    to.params.workspaceId = String(isTokenUsed.workspaceId);
                    to.params.email = isTokenUsed.email;
                }

                if (authStore.isLoggedIn) {
                    await workspaceStore.getWorkspaces();
                    const hasWorkspaces = workspaceStore.workspaces.length;

                    if (hasWorkspaces) {
                        return next({ name: "Dashboard" });
                    }
                }
                //temporarily redirect to login if not has state in query params from invite
                if (!to.query.state) {
                    return next({ name: "Login" });
                }

                next();
            } catch {
                if (authStore.isLoggedIn) {
                    return next({ name: "Login" });
                }
                next();
            }
        },
    },
    {
        path: "/email-verification",
        component: EmptyRouterView,
        name: "EmailVerification",
        meta: {
            metaTitle: "auth.register.text_title",
            requiresAuth: false,
        },
        beforeEnter: async (to, _from, next) => {
            await authService.emailValidation(to.query.state as string);

            next({ name: "Dashboard" });
        },
    },
    {
        path: "/credentials/recover",
        component: EmptyRouterView,
        meta: {
            metaTitle: "auth.recover.text_title",
            requiresAuth: false,
        },
        children: [
            {
                path: "",
                name: "Recover",
                component: () => import("@/modules/base/auth/views/recover.vue"),
            },
            {
                path: ":recoverToken",
                name: "RecoverToken",
                component: () => import("@/modules/base/auth/views/update-password.vue"),
            },
        ],
    },
    {
        path: "/logout",
        component: EmptyRouterView,
        name: "Logout",
        beforeEnter(_to, _from, next) {
            const authStore = useAuthStore();
            authStore.logout();

            next("/login");
        },
        meta: {
            requiresAuth: false,
        },
    },
];
