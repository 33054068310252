import { AxiosInstance } from "axios";
import { TGetAvailableIssuesOutput, TGetAvailableIssuesParams } from "./types/issues.types";

export class IssuesHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }
    //getCrawlerAvailableIssues
    async getAvailableIssues({
        workspaceId,
        projectId,
        crawlerId,
    }: TGetAvailableIssuesParams): Promise<TGetAvailableIssuesOutput> {
        const { data } = await this.httpClient.get(
        `/workspaces/${workspaceId}/projects/${projectId}/crawlers/issues/all`, {
            params: {
                crawlerId,
            },
        });

        return data as TGetAvailableIssuesOutput;
    }
}
