import { AxiosInstance } from "axios";

import type {
    TUser,
    TDeleteUserParams,
    TLoginParams,
    TLoginWithGoogleCallbackParams,
    TRefreshTokenParams,
    TRegisterParams,
    TResetPasswordParams,
    TUpdateUserParams,
    TLoginOutput,
    TRefreshTokenOutput,
} from "./types/auth.types";

export class AuthHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async registerUser({
        email,
        name,
        lastName,
        language,
        password,
        phoneNumber,
    }: TRegisterParams): Promise<TUser> {
        const { data } = await this.httpClient.post(
            "/users",
            {
                email,
                name,
                lastName,
                language,
                password,
                phoneNumber,
            },
            { headers: { NeedAuth: false } },
        );

        return data as TUser;
    }

    async registerInvitedUser({
        email,
        name,
        lastName,
        language,
        password,
        phoneNumber,
        state,
    }: TRegisterParams): Promise<TUser> {
        const { data } = await this.httpClient.post(
            "/users/invite/callback?state=" + state,
            { email, name, lastName, language, password, phoneNumber },
            { headers: { NeedAuth: false } },
        );

        return data as TUser;
    }

    async login({ email, password }: TLoginParams): Promise<TLoginOutput> {
        const { data } = await this.httpClient.post(
            "/auth/login",
            { email, password },
            { headers: { NeedAuth: false } },
        );

        return data as TLoginOutput;
    }

    async loginWithGoogle(): Promise<string> {
        const { data: url } = await this.httpClient.get("/auth/login/google", {
            headers: { NeedAuth: false },
        });

        return url as string;
    }

    async loginWithGoogleCallback(
        loginParams: TLoginWithGoogleCallbackParams,
    ): Promise<TLoginOutput> {
        const { data } = await this.httpClient.post("/auth/login/google/callback", loginParams, {
            headers: { NeedAuth: false },
        });

        return data as TLoginOutput;
    }

    async refreshToken({
        userId,
        currentRefreshToken,
    }: TRefreshTokenParams): Promise<TRefreshTokenOutput> {
        const { data } = await this.httpClient.post(
            "/auth/login/refresh",
            {
                id: userId,
                token: currentRefreshToken,
            },
            { headers: { NeedAuth: false } },
        );

        return data as TRefreshTokenOutput;
    }

    async refetchCurrentUser(): Promise<TUser> {
        const { data } = await this.httpClient.get("/users");

        return data as TUser;
    }

    async updateUser({
        name,
        lastName,
        email,
        language,
        password,
        currentPassword,
        phoneNumber,
    }: TUpdateUserParams): Promise<TUser> {
        const { data } = await this.httpClient.put("/users", {
            name,
            lastName,
            email,
            language,
            password,
            currentPassword,
            phoneNumber,
        });

        return data as TUser;
    }

    async deleteUser({ userId, removedBy }: TDeleteUserParams): Promise<boolean> {
        const { data } = await this.httpClient.delete("/users", {
            params: {
                userId,
                removedBy,
            },
        });

        return data as boolean;
    }

    async recoverPassword(email: string): Promise<boolean> {
        const { data } = await this.httpClient.post(
            "/users/credentials/recover",
            { email },
            { headers: { NeedAuth: false } },
        );

        return data as boolean;
    }

    async resetPassword({ token, password }: TResetPasswordParams): Promise<boolean> {
        const { data } = await this.httpClient.post(
            "/users/credentials/reset",
            { token, password },
            { headers: { NeedAuth: false } },
        );

        return data as boolean;
    }

    async verifyEmailAlreadyInUse(email: string): Promise<{ inUse: boolean }> {
        const { data } = await this.httpClient.get("/users/credentials/verify-email", {
            params: { email },
            headers: { NeedAuth: false },
        });

        return data as { inUse: boolean };
    }

    async resendEmailValidation(userId: number): Promise<boolean> {
        const { data } = await this.httpClient.get("/users/resend-email-validation", {
            params: { user_id: userId },
        });

        return data.success as boolean;
    }

    async emailValidation(token: string): Promise<boolean> {
        const { data } = await this.httpClient.post(
            "/users/email-validation",
            { state: token },
            { headers: { NeedAuth: false } },
        );

        return data.success as boolean;
    }
}
