import { Router } from "vue-router";

import { useAuthStore } from "@/modules/base/auth/auth.store";

export function authMiddleware(router: Router) {
    router.beforeEach((to, _from, next) => {
        const authStore = useAuthStore();

        authStore.storeLoginIfAuthenticated();

        if (authStore.isLoggedIn || (!to.meta.requiresAuth && to.meta.requiresAuth != undefined)) {
            next();
        } else {
            next({ name: "Login", query: to.query });
        }
    });
}
