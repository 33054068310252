<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 135 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M34.2402 99.7917C37.4255 99.7917 40.0077 102.405 40.0077 105.629C40.0077 123.362 54.2099 137.737 71.7292 137.737C89.2484 137.737 103.451 123.362 103.451 105.629C103.451 102.405 106.033 99.7917 109.218 99.7917C112.403 99.7917 114.986 102.405 114.986 105.629C114.986 129.81 95.6191 149.412 71.7292 149.412C47.8392 149.412 28.4726 129.81 28.4726 105.629C28.4726 102.405 31.0549 99.7917 34.2402 99.7917Z"
            fill="#FA3D8B"
        />
        <path
            d="M119.26 107.033C118.82 108.584 119.713 110.2 121.253 110.643C122.794 111.086 124.399 110.188 124.839 108.636C127.26 100.1 122.35 91.2046 113.872 88.7673C105.395 86.3299 96.5599 91.2739 94.1393 99.81C93.6994 101.361 94.5917 102.978 96.1322 103.421C97.6727 103.864 99.2782 102.965 99.718 101.414C101.259 95.9802 106.883 92.8331 112.279 94.3846C117.676 95.9361 120.801 101.599 119.26 107.033Z"
            fill="#FA3D8B"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M117.199 37.7705C117.438 39.322 118.647 40.5538 120.177 40.8525C135.314 43.8061 146.744 57.2228 146.744 73.3276V141.693C146.744 159.963 132.035 174.773 113.891 174.773H32.853C14.7088 174.773 0 159.963 0 141.693V73.3276C0 57.2228 11.4294 43.8061 26.5664 40.8525C28.0971 40.5538 29.3059 39.322 29.5445 37.7705C32.8337 16.3767 51.2034 0 73.3718 0C95.5402 0 113.91 16.3767 117.199 37.7705ZM101.622 40.2475C103.612 40.2475 105.157 38.4723 104.661 36.5319C101.077 22.499 88.427 12.1294 73.3718 12.1294C58.3166 12.1294 45.6667 22.499 42.0825 36.5319C41.5869 38.4723 43.1317 40.2475 45.1215 40.2475H101.622ZM12.0461 73.3276C12.0461 61.7568 21.3617 52.3768 32.853 52.3768H113.891C125.382 52.3768 134.697 61.7568 134.697 73.3276V141.693C134.697 153.264 125.382 162.644 113.891 162.644H32.853C21.3617 162.644 12.0461 153.264 12.0461 141.693V73.3276Z"
            fill="#FA3D8B"
        />
    </svg>
</template>
