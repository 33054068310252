import { RouteRecordRaw } from "vue-router";

export const dashboardRoutes: RouteRecordRaw[] = [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("./views/dashboard.vue"),
        meta: {
            metaTitle: "base.breadcrumb.text_dashboard",
            title: "base.breadcrumb.text_dashboard",
        },
    },
];
