import { api } from "@/plugins/api/api";

import { AuthHttp } from "@/modules/base/auth/auth.http";
import { WorkspaceHttp } from "@/modules/base/workspace/workspace.http";
import { ProjectHttp } from "@/modules/base/project/project.http";
import { CrawlerHttp } from "@/modules/crawler/crawler.http";
import { CustomerSupportHttp } from "@/modules/base/customer-support/customer-support.http";
import { UploadHttp } from "@/utils/upload/upload.http";
import { IssuesHttp } from "@/modules/task/issues.http";
import { PaymentHttp } from "@/modules/payment/payment.http";

export const authHttp = new AuthHttp(api);
export const workspaceHttp = new WorkspaceHttp(api);
export const projectHttp = new ProjectHttp(api);
export const crawlerHttp = new CrawlerHttp(api);
export const customerSupportHttp = new CustomerSupportHttp(api);
export const uploadHttp = new UploadHttp(api);
export const issuesHttp = new IssuesHttp(api);
export const paymentHttp = new PaymentHttp(api);
