<template>
    <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
            <path
                d="M28.1929 10.8083C29.2648 10.8083 30.3126 10.4913 31.2038 9.89751C32.0951 9.3037 32.7897 8.45969 33.1999 7.47221C33.6101 6.48474 33.7174 5.39814 33.5083 4.34984C33.2992 3.30154 32.783 2.33862 32.0251 1.58284C31.2672 0.827055 30.3015 0.312361 29.2502 0.103841C28.1989 -0.104678 27.1093 0.00234152 26.119 0.411368C25.1287 0.820394 24.2823 1.51306 23.6868 2.40176C23.0913 3.29047 22.7734 4.3353 22.7734 5.40414C22.7734 6.83741 23.3444 8.21197 24.3608 9.22544C25.3771 10.2389 26.7556 10.8083 28.1929 10.8083Z"
                fill="#371E56"
            />
            <path
                d="M22.5532 26.7465C19.7663 26.7458 17.0629 25.7976 14.8891 24.0585C12.7154 22.3193 11.2013 19.8931 10.5966 17.1803C10.4033 16.564 10.305 15.9221 10.3049 15.2763V7.97282C10.3049 6.61017 9.76206 5.30334 8.79579 4.3398C7.82952 3.37627 6.51897 2.83496 5.15246 2.83496C3.78594 2.83496 2.47539 3.37627 1.50912 4.3398C0.542846 5.30334 0 6.61017 0 7.97282L0 15.0224C0 24.3761 7.63915 32.0644 17.0164 31.8782C20.0228 31.8182 22.9569 30.9484 25.508 29.3611C28.0591 27.7738 30.1321 25.5279 31.5075 22.8615C30.3632 24.0885 28.9774 25.0668 27.4367 25.7352C25.8959 26.4037 24.2334 26.748 22.5532 26.7465Z"
                fill="#2BC4C3"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="14.2648"
                y1="35.1427"
                x2="23.1722"
                y2="15.222"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.29" stop-color="#0C9898" />
                <stop offset="1" stop-color="#2BC4C3" />
            </linearGradient>
            <clipPath id="clip0">
                <rect width="215" height="32" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
