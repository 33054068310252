import { RouteRecordRaw } from "vue-router";

export const userRoutes: RouteRecordRaw[] = [
    {
        path: "/user/settings",
        name: "Settings",
        component: () => import("@/modules/base/user/views/settings.vue"),
        meta: {
            metaTitle: "base.breadcrumb.text_settings",
            title: "base.breadcrumb.text_settings",
        },
    },
];
