import { AxiosInstance, AxiosResponse } from "axios";

import type {
    TCrawler,
    TExportIssueURLsParams,
    TGetCrawledURLsOutput,
    TGetCrawledURLsParams,
    TGetCrawlerAnalyticsOutput,
    TGetCrawlerAnalyticsParams,
    TGetCrawlerBlockedURLsOutput,
    TGetCrawlerBlockedURLsParams,
    TGetCrawlerIssueURLsOutput,
    TGetCrawlerIssueURLsParams,
    TGetCrawlerUrlDetailsOutput,
    TGetCrawlerUrlDetailsParams,
    TGetCrawlersParams,
    TMarkIssueURLasResolvedParams,
    TRunCrawlerParams,
    TStopCrawlerParams,
    TUpdateIssueOutput,
    TUpdateIssueParams,
} from "./types/crawler.types";

export class CrawlerHttp {
    constructor(private readonly httpClient: AxiosInstance) {
        this.httpClient = httpClient;
    }

    async runCrawler({
        workspaceId,
        projectId,
        ...crawlerConfig
    }: TRunCrawlerParams): Promise<{ started: boolean }> {
        const { data } = await this.httpClient.post(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/start`,
            crawlerConfig,
        );

        return data as { started: boolean };
    }

    async getCrawlers({
        workspaceId,
        projectId,
        page = 1,
        limit = 10,
    }: TGetCrawlersParams): Promise<{ crawlers: TCrawler[]; total: number }> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers`,
            {
                params: {
                    page,
                    limit,
                },
            },
        );

        return data as { crawlers: TCrawler[]; total: number };
    }

    async stopCrawler({ id, workspaceId, projectId }: TStopCrawlerParams): Promise<void> {
        return await this.httpClient.put(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/${id}/stop`,
        );
    }

    async getCrawledURLs({
        workspaceId,
        projectId,
        crawlerId,
        page = 1,
        limit = 10,
        withErrors,
        internal,
        indexable,
        contentTypes,
        statusCodes,
        depths,
    }: TGetCrawledURLsParams): Promise<TGetCrawledURLsOutput> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/${crawlerId}/urls`,
            {
                params: {
                    page,
                    limit,
                    withErrors,
                    internal,
                    indexable,
                    contentTypes,
                    statusCodes,
                    depths,
                },
            },
        );

        return data as TGetCrawledURLsOutput;
    }

    async getCrawlerAnalytics({
        workspaceId,
        projectId,
        charts,
        page,
        limit,
        crawlerId,
    }: TGetCrawlerAnalyticsParams): Promise<TGetCrawlerAnalyticsOutput> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/analytics`,
            {
                params: {
                    crawlerId,
                    charts,
                    page,
                    limit,
                },
            },
        );

        return data as TGetCrawlerAnalyticsOutput;
    }

    async getCrawlerBlockedURLs({
        workspaceId,
        projectId,
        crawlerId,
        charts,
        page = 1,
        limit = 10,
    }: TGetCrawlerBlockedURLsParams): Promise<TGetCrawlerBlockedURLsOutput> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/${crawlerId}/analytics/blocked-urls`,
            {
                params: {
                    charts,
                    page,
                    limit,
                },
            },
        );

        return data as TGetCrawlerBlockedURLsOutput;
    }

    async getCrawlerUrlDetails({
        workspaceId,
        projectId,
        crawlerId,
        crawledUrlId,
    }: TGetCrawlerUrlDetailsParams): Promise<TGetCrawlerUrlDetailsOutput> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/${crawlerId}/details/${crawledUrlId}`,
        );

        return data as TGetCrawlerUrlDetailsOutput;
    }

    async getCrawlerIssueURLs({
        workspaceId,
        projectId,
        crawlerId,
        issue,
        page = 1,
        limit = 10,
    }: TGetCrawlerIssueURLsParams): Promise<TGetCrawlerIssueURLsOutput> {
        const { data } = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/issues`,
            {
                params: {
                    crawlerId,
                    issue,
                    page,
                    limit,
                },
            },
        );

        return data as TGetCrawlerIssueURLsOutput;
    }

    async exportIssueURLs({
        workspaceId,
        projectId,
        crawlerId,
        issue,
        page = 1,
        limit = 10000,
        headers,
    }: TExportIssueURLsParams): Promise<AxiosResponse> {
        const res = await this.httpClient.get(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/issues/${issue}/export`,
            {
                params: {
                    crawlerId,
                    page,
                    limit,
                    headers,
                },
            },
        );

        return res;
    }

    async updateIssue({
        workspaceId,
        projectId,
        issue,
        status,
        description,
    }: TUpdateIssueParams): Promise<TUpdateIssueOutput> {
        const { data } = await this.httpClient.put(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/issues`,
            undefined,
            {
                params: {
                    issue,
                    status,
                    description,
                },
            },
        );

        return data as TUpdateIssueOutput;
    }

    async markIssueURLasResolved({
        workspaceId,
        projectId,
        crawlerId,
        issue,
        rows,
    }: TMarkIssueURLasResolvedParams): Promise<{ updated: boolean }> {
        const { data } = await this.httpClient.put<{ updated: boolean }>(
            `/workspaces/${workspaceId}/projects/${projectId}/crawlers/issues/${issue}/resolve`,
            {
                crawlerId,
                rows,
            },
        );

        return data;
    }
}
