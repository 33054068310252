//customSvgs.ts
import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import GoogleIcon from "./google-icon.vue";
import NuvemshopIcon from "./nuvemshop-icon.vue";
import VtexIcon from "./vtex-icon.vue";
import LojaIntegradaIcon from "./loja-integrada-icon.vue";
import GoogleAnalyticsIcon from "./google-analytics-icon.vue";
import GoogleSearchConsoleIcon from "./google-search-console-icon.vue";
import DecoIcon from "./deco-icon.vue";
import TrayIcon from "./tray-icon.vue";
import ShopifyIcon from "./shopify-icon.vue";
import LinxIcon from "./linx-icon.vue";
import WoocomerceIcon from "./woocomerce-icon.vue";
import Bis2bisIcon from "./bis2bis-icon.vue";
import BagyIcon from "./bagy-icon.vue";
import WakeIcon from "./wake-icon.vue";
import VisaIcon from "./visa-icon.vue";
import MastercardIcon from "./mastercard-icon.vue";
import HipercardIcon from "./hipercard-icon.vue";
import AmexIcon from "./amex-icon.vue";
import DinersIcon from "./diners-icon.vue";
import EloIcon from "./elo-icon.vue";
import DefaultCardIcon from "./default-card-icon.vue";

const customSvgNameToComponent: Record<string, any> = {
    GoogleIcon,
    NuvemshopIcon,
    VtexIcon,
    LojaIntegradaIcon,
    GoogleAnalyticsIcon,
    GoogleSearchConsoleIcon,
    DecoIcon,
    TrayIcon,
    ShopifyIcon,
    LinxIcon,
    WoocomerceIcon,
    Bis2bisIcon,
    BagyIcon,
    WakeIcon,
    VisaIcon,
    MastercardIcon,
    HipercardIcon,
    AmexIcon,
    DinersIcon,
    EloIcon,
    DefaultCardIcon,
};

export const customSVGs: IconSet = {
    component: (props: IconProps) => h(customSvgNameToComponent[props.icon as string]),
};
