import { RouteRecordRaw } from "vue-router";

import type { TIssues } from "../crawler/types/crawler.types";
import { baseIssues } from "./constants/base-issues";
import { verifyIssueRouteName } from "./composable/verify-issue-route-name";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

export const issuesRoutes: RouteRecordRaw[] = [
    {
        path: "tasks",
        meta: {
            metaTitle: "base.breadcrumb.text_task",
            title: "base.breadcrumb.text_task",
        },
        component: EmptyRouterView,
        children: [
            {
                path: "",
                name: "IssuesList",
                component: () => import("@/modules/task/views/issues-list.vue"),
            },
            {
                path: ":issueId",
                name: "IssueDetails",
                component: () => import("@/modules/task/views/issue-details.vue"),
                meta: {
                    metaTitle: "base.breadcrumb.text_task_details",
                    title: "base.breadcrumb.text_task_details",
                },
                beforeEnter: async (to, _from, next) => {
                    const originalIssueId = to.params.issueId as string;
                    const verifiedIssueId = verifyIssueRouteName(originalIssueId);
                    if (originalIssueId !== verifiedIssueId) {
                        return next({ name: "IssueDetails", params: { issueId: verifiedIssueId } });
                    } else {
                        try {
                            if (!originalIssueId.includes("_TASK")) {
                                let issueName = originalIssueId.replace(/-/g, "_");
                                to.params.issueId = issueName.toUpperCase() + "_TASK";
                            }

                            const issueId = to.params.issueId as TIssues;

                            if (!baseIssues.value[issueId]) {
                                return next({ name: "NotFound", query: { from: to.fullPath } });
                            }

                            next();
                        } catch (err) {
                            next();
                        }
                    }
                },
                children: [
                    {
                        path: "crawler/:crawlerId/url/:crawledUrlId",
                        name: "TaskCrawledUrlOverview",
                        component: () => import("@/modules/crawler/views/crawler-url-overview.vue"),
                        meta: {
                            metaTitle: "base.breadcrumb.text_url_overview",
                            title: "base.breadcrumb.text_url_overview",
                        },
                    },
                ],
            },
        ],
    },
];
