import { Router } from "vue-router";

import { t } from "@/plugins/i18n";
import { useAppStore } from "@/stores/app.store";

export function metaTitleMiddleware(router: Router) {
    router.beforeEach((to) => {
        const environment = useAppStore().environment;

        let environmentPrefix = "";
        if (environment === "dev") {
            environmentPrefix = "Dev - ";
        }

        if (environment === "qa") {
            environmentPrefix = "QA - ";
        }

        const metaTitle = to.meta.metaTitle as string | undefined;

        if (metaTitle) {
            document.title = `${environmentPrefix}${t(metaTitle)} | JourneySEO`;
            return;
        }

        document.title = "JourneySEO";
    });
}
