import { RouteRecordRaw } from "vue-router";

const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

export const connectionsRoutes: RouteRecordRaw[] = [
    {
        path: "connections",
        component: EmptyRouterView,
        meta: {
            title: "base.breadcrumb.text_connections",
        },
        children: [
            {
                path: "",
                name: "ConnectionsList",
                component: () => import("@/modules/connections/views/connections-list.vue"),
                meta: {
                    title: "base.breadcrumb.text_connections",
                    hideBreadcrumb: true,
                },
            },
        ],
    },
];
