<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 1920 1124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_345_10447)">
            <path
                d="M1250.03 287.574C1250.03 451.916 1342.5 609.384 1379.94 663.436C1384.23 669.75 1389.92 674.95 1396.56 678.614C1403.2 682.279 1410.6 684.304 1418.16 684.525H1654.87C1662.46 684.46 1669.91 682.502 1676.58 678.824C1683.25 675.146 1688.92 669.861 1693.09 663.436C1730.54 608.291 1823 450.822 1823 287.574H1250.03Z"
                fill="#43A0D6"
            />
            <path
                d="M1209.34 827.792C1223.37 804.355 1236 780.917 1247.72 757.323C1236.31 781.562 1223.5 805.093 1209.34 827.792V827.792Z"
                fill="#002C6A"
            />
            <path
                d="M1275.46 700.928C1268.52 714.991 1261.59 730.303 1254.65 744.366C1261.59 730.303 1268.22 716.241 1275.46 700.928Z"
                fill="#002C6A"
            />
            <path
                d="M1480.11 288.667H1397.36C1394.43 314.599 1389.81 342.25 1384.1 371.151C1380.4 389.428 1373.78 424.421 1356.67 484.566C1348.04 514.716 1314.6 610.79 1307.51 625.162C1306.28 623.913 1293.64 602.979 1287.94 592.669C1274.41 568.531 1262.32 543.596 1251.73 517.996C1250.34 514.559 1241.09 489.721 1239.55 485.347C1235.54 474.099 1229.07 454.103 1226.14 445.667C1210.12 394.61 1199.43 341.988 1194.24 288.667H1064.33V354.435H1120.12C1127.84 397.229 1139.08 439.294 1153.71 480.191C1177.67 549.955 1211.75 615.715 1254.81 675.309C1256.96 678.433 1266.52 690.93 1270.22 694.211L1275.77 700.928C1268.83 714.988 1261.9 730.298 1254.96 744.357C1252.65 749.044 1250.34 752.637 1248.03 757.323C1235.24 780.912 1222.44 805.439 1209.65 827.778H1285.17C1300.58 802.002 1317.53 776.07 1331.4 749.669C1372.8 667.737 1408.08 582.777 1436.96 495.501C1457.26 427.961 1471.7 358.754 1480.11 288.667Z"
                fill="#002C6A"
            />
            <path
                d="M1269.76 917.135C1269.76 927.146 1266.83 936.932 1261.34 945.255C1255.86 953.579 1248.06 960.066 1238.93 963.897C1229.81 967.728 1219.77 968.73 1210.09 966.777C1200.4 964.824 1191.5 960.004 1184.52 952.925C1177.54 945.847 1172.78 936.828 1170.85 927.01C1168.93 917.191 1169.92 907.014 1173.7 897.766C1177.48 888.517 1183.88 880.612 1192.09 875.05C1200.3 869.489 1209.95 866.52 1219.83 866.52C1233.04 866.602 1245.7 871.961 1255.04 881.436C1264.39 890.91 1269.68 903.737 1269.76 917.135V917.135Z"
                fill="#002C6A"
            />
            <path
                d="M1720.98 917.135C1720.98 927.146 1718.05 936.931 1712.56 945.255C1707.08 953.579 1699.28 960.066 1690.16 963.897C1681.03 967.728 1670.99 968.73 1661.31 966.777C1651.62 964.824 1642.73 960.004 1635.74 952.925C1628.76 945.846 1624 936.828 1622.08 927.009C1620.15 917.191 1621.14 907.014 1624.92 897.765C1628.7 888.517 1635.1 880.612 1643.31 875.05C1651.52 869.489 1661.17 866.52 1671.05 866.52C1677.62 866.457 1684.14 867.724 1690.23 870.245C1696.32 872.767 1701.84 876.493 1706.49 881.205C1711.14 885.918 1714.82 891.522 1717.3 897.692C1719.79 903.861 1721.04 910.471 1720.98 917.135V917.135Z"
                fill="#002C6A"
            />
            <path
                d="M288.401 712.645C204.875 712.645 156.177 669.216 156.177 567.049V353.342H97V286.324H156.177V191.187L238.47 186.5V286.324H340.643V353.342H238.47V565.955C238.47 621.101 261.741 642.19 302.271 642.19C318.317 642.25 334.311 640.308 349.89 636.41L354.513 703.272C333.057 709.706 310.775 712.866 288.401 712.645V712.645Z"
                fill="#002C6A"
            />
            <path
                d="M609.712 275.701C629.186 275.468 648.623 277.46 667.657 281.637L656.099 352.092C643.001 347.889 629.302 345.936 615.569 346.312C582.635 345.72 550.021 352.942 520.33 367.401V703.272H438.037V315.693C491.468 289.161 550.215 275.476 609.712 275.701V275.701Z"
                fill="#002C6A"
            />
            <path
                d="M862.604 712.645C754.729 712.645 701.408 659.843 701.408 587.67C701.408 502.062 769.831 459.727 862.604 459.727C891.818 459.783 920.948 462.925 949.52 469.1V435.513C949.52 378.024 918.699 346.312 853.357 346.312C815.569 345.94 778.077 353.106 743.017 367.401L726.835 300.383C769.94 284.491 815.505 276.555 861.371 276.951C973.869 276.951 1029.66 335.689 1029.66 440.199V676.246C977.756 702.073 920.389 714.573 862.604 712.645V712.645ZM948.441 632.817V524.714C923.227 518.431 897.352 515.284 871.388 515.34C820.378 515.34 783.239 532.993 783.239 584.702C783.239 628.13 814.06 650.47 869.076 650.47C896.607 651.82 923.997 645.728 948.441 632.817V632.817Z"
                fill="#002C6A"
            />
        </g>
        <defs>
            <clipPath id="clip0_345_10447">
                <rect width="1726" height="875" fill="white" transform="translate(97 124)" />
            </clipPath>
        </defs>
    </svg>
</template>
