import { RouteRecordRaw } from "vue-router";

export const projectRoutes = (projectChildrens: RouteRecordRaw[]): RouteRecordRaw[] => {
    const EmptyRouterView = () => import("@/layout/empty-router-view.vue");

    return [
        {
            path: ":workspaceId/projects",
            component: EmptyRouterView,
            meta: {
                title: "base.breadcrumb.text_projects",
                hideBreadcrumb: true,
            },
            redirect: { name: "Dashboard" },
            children: [
                {
                    path: ":projectId",
                    component: EmptyRouterView,
                    meta: {
                        metaTitle: "base.breadcrumb.text_project_dashboard",
                        title: "base.breadcrumb.text_project_dashboard",
                    },
                    children: [
                        {
                            path: "",
                            name: "DashboardProject",
                            component: () =>
                                import("@/modules/base/project/views/dashboard-project.vue"),
                        },
                        ...projectChildrens,
                    ],
                },
            ],
        },
    ];
};
